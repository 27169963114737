import React from 'react';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore, InfoStore, ModalStore } from "../stores";
import { Modals } from "../stores/ModalStore";
import OrderBook from "../components/exchange/OrderBook";
import TelegramLoginButton from 'react-telegram-login'
import { toast } from "react-toastify";
import { processRequestError } from "../utils/utilities";

interface IIndexPageProps {
}

interface IIndexPageState {
}

interface TelegramUser {  id: number
    photo_url: string  
    first_name: string
    last_name: string  
    username: string
    auth_date: number  
    hash: string
  }

@observer
export class IndexPage extends React.Component<IIndexPageProps, IIndexPageState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(InfoStore)
    declare protected readonly infoStore: InfoStore;

    state: IIndexPageState = {
    }

    handleTelegramResponse = async (user: TelegramUser) => {    
        // console.log(user);
    // auth.login(initialData)

        try {
            await this.authStore.tokenAuth();
            toast.success('Вход выполнен успешно');
            this.modalStore.hideModals();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }    

    }

    bot_name = 'exchange_dev_koneko_fun_bot';
    
    render() {
        return (
            <main className="main">
                <section className="main-section">
                    <div className="container">
                        <div className="main-content">
                            <div className="info-wrap">
                                <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.topText }} />
                                {/*<h1 className="main-title">Криптовалютная биржа</h1>
                                <div className="purpose">
                                    <ul className="purpose__list">
                                        <li>Наличные и безналичные рубли, Bitcoin, USDT</li>
                                        <li>Вывод на карты Сбербанк, Тиньков, Альфа-банк</li>
                                        <li>Минимальные комиссии от 0.1% до 0.2% за сделку</li>
                                        <li>Биржа работает круглосуточно в полном объеме</li>
                                    </ul>
                                </div>*/}
                                {!this.authStore.token && (
                                    <div className="btn-wrap">
                                        <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Зарегистрироваться</button>
                                        <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>Войти в аккаунт</button>

                                        <TelegramLoginButton dataOnauth={this.handleTelegramResponse} botName={this.bot_name} usePic={false} />

                                    </div>
                                )}
                            </div>
                            {/*<div className="main-img"><img src={require('../images/main-img.svg')} alt=""/></div>*/}
                            <div className="main-img"><img src={this.infoStore.indexPage?.topImage} alt=""/></div>
                        </div>
                        <OrderBook symbol={"BTCRUB"} coinSymbol={"BTC"} baseSymbol={"RUB"}/>

                    </div>
                </section>
                <section className="purpose-section">
                    <div className="container">
                        <div className="purpose-content">
                            <h2 className="section-title">Для кого предназначена наша биржа</h2>
                            <div className="purpose-wrap">
                                <div className="purpose">
                                    <div className="purpose__wrap">
                                        {/*<div className="purpose__img"><img src={require('../images/purpose1.svg')} alt=""/></div>*/}
                                        <div className="purpose__img"><img src={this.infoStore.indexPage?.bottomImage1} alt=""/></div>
                                        {/*<span className="purpose__title">Трейдер</span>
                                        <ul className="purpose__list">
                                            <li>Единственная биржа с возможностью пополнения или вывода баланса
                                                наличными
                                            </li>
                                            <li>Работаем по всей России</li>
                                            <li>Вывод рублей на карты/счета Сбербанка, Тинькова, Альфабанка, ВТБ</li>
                                            <li>Комиссия всего от 0.1% за сделку</li>
                                        </ul>*/}
                                        <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.bottomText1 }} />
                                    </div>
                                </div>
                                <div className="purpose">
                                    <div className="purpose__wrap">
                                        {/*<div className="purpose__img"><img src={require('../images/purpose2.svg')} alt=""/></div>*/}
                                        <div className="purpose__img"><img src={this.infoStore.indexPage?.bottomImage2} alt=""/></div>
                                        {/*<span className="purpose__title">Майнер</span>
                                        <ul className="purpose__list">
                                            <li>Продавая свою криптовалюту на бирже вы гарантировано получите наиболее
                                                высокий курс и избежите лишних комиссий посредников
                                            </li>
                                            <li>Комфортная, автоматизированная сделка, не вставая из за компьютера</li>
                                            <li>Получение наличных в кассе, любые регионы, карты, счета, кэшины</li>
                                        </ul>*/}
                                        <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.bottomText2 }} />
                                    </div>
                                </div>
                                <div className="purpose">
                                    <div className="purpose__wrap">
                                        {/*<div className="purpose__img"><img src={require('../images/purpose3.svg')} alt=""/></div>*/}
                                        <div className="purpose__img"><img src={this.infoStore.indexPage?.bottomImage3} alt=""/></div>
                                        {/*<span className="purpose__title">Обменник</span>
                                        <ul className="purpose__list">
                                            <li>Несколько вариантов интеграции</li>
                                            <li>Низкая комиссия на отправку криптовалюты</li>
                                            <li>Множество обменников уже зарабатывают с биржей.</li>
                                            <li>Свяжитесь с нами, чтобы узнать о возможных вариантах интеграции</li>
                                        </ul>*/}
                                        <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.bottomText3 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {!this.authStore.token && (
                    <section className="register-section">
                        <div className="container">
                            <div className="register-content">
                                <h2 className="section-title">Зарегистрируй аккаунт прямо сейчас</h2>
                                <div className="btn-wrap">
                                    <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Зарегистрироваться</button>
                                    <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>Войти в аккаунт</button>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </main>
        )
    }
}
