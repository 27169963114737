import React from 'react';
import { HistoryDeposit } from "../../components/HistoryDeposit";
import classNames from "classnames";
import { Button } from "../../components/Button";
import { pd, processRequestError, tf } from "../../utils/utilities";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { Api } from "../../utils/api";
import { AuthStore, CoinStore } from "../../stores";
import { OperationsType } from "../../utils/graphql";
import { toast } from "react-toastify";
import { HistoryWithdrawRub } from "../../components/HistoryWithdrawRub";
import {BalanceExchangeType} from "../../utils/graphql";
import {rootStore} from "../../Application";
import BalanceLine from '../../components/active/BalanceLine';
import { ExchangeExmoCodeCurrencyChoices } from '../../utils/graphql';
import WithdrawalMethod from '../../components/WithdrawalMethod';
import DepositMethod from '../../components/DepositMethod';

interface IAssetsKztTabProps {
}

interface IAssetsKztTabState {
    activeTab: 'deposit' | 'withdraw';
    depositCode: string;
    withdrawAmount: string;
    operations?: OperationsType;
    operationsExmoCode?: any;
    loading: boolean;
    otpCode: string;
}

@observer
export class AssetsKztTab extends React.Component<IAssetsKztTabProps, IAssetsKztTabState> {
    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(CoinStore)
    declare protected readonly coinStore: CoinStore;

    state: IAssetsKztTabState = {
        activeTab: 'deposit',
        depositCode: '',
        withdrawAmount: '',
        loading: false,
        otpCode: '',
    }

    componentDidMount() {
        rootStore.coinBaze.setSelectDeposite('MaxCode');
        rootStore.coinBaze.setSelectWithdrawal('MaxCode');
        this.loadOperations();
    }

    loadOperations = async () => {
        this.setState({ operations: await this.api.getOperations('kzt') });
        const operationsExmoCodeCopy = await this.api.getMyExmoCode();
        this.setState({operationsExmoCode: operationsExmoCodeCopy.filter(element => element.currency === ExchangeExmoCodeCurrencyChoices.Kzt)});
    }

    onDepositMaxCode = async () => {
        await this.api.depositKzt(this.state.depositCode);
    }

    onDepositExmoCode = async () => {
        await this.api.redeemExmoCode(this.state.depositCode);
    }

    onDeposit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            switch(rootStore.coinBaze.getSelectDeposite()) {
                case 'MaxCode':
                    await this.onDepositMaxCode();
                    break;
                case 'ExmoCode':
                    await this.onDepositExmoCode();
                    break;                
            }
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success('Пополнение произведено успешно');
            this.setState({ depositCode: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
            await this.coinStore.getBalancesAll()
        }
    }

    onWithdrawMaxCode = async () => {
        await this.api.withdrawRub(this.state.withdrawAmount, this.state.otpCode);
    }

    onWithdrawExmoCode = async () => {
        await this.api.createExmoCode(ExchangeExmoCodeCurrencyChoices.Usdt, Number(this.state.withdrawAmount));
    }

    onWithdraw = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            switch(rootStore.coinBaze.getSelectWithdrawal()) {
                case 'MaxCode':
                    await this.onWithdrawMaxCode();
                    break;
                case 'ExmoCode':
                    await this.onWithdrawExmoCode();
                    break;                
            }
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success('Код пополнения создан, см. в истории вывода');
            this.setState({ withdrawAmount: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
            await this.coinStore.getBalancesAll()
        }
    }

    render() {
        const { activeTab, depositCode, loading, operations, operationsExmoCode, withdrawAmount, otpCode } = this.state;
        const withdrawAmountNumber = parseFloat(withdrawAmount);
        const profile = this.authStore.profile;

        return (
            <div className="tabs__content active">
                <div className="deal">
                    <ul className="deal__list">
                        <li className={classNames('deal__item', { active: activeTab === 'deposit' })} onClick={() => this.setState({ activeTab: 'deposit' })}>Пополнение</li>
                        <li className={classNames('deal__item', { active: activeTab === 'withdraw' })} onClick={() => this.setState({ activeTab: 'withdraw' })}>Вывод</li>
                    </ul>
                    <div className={classNames('deal__content', { active: activeTab === 'deposit' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='KZT' />
                                <div className="bye-sell">
                                    <form className="main-form" onSubmit={this.onDeposit}>
                                        <div className="main-form__wrap">
                                            <DepositMethod/>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Условия</label>
                                                <p className="base-text cond">Комиссия 0%. Без
                                                    ограничений. Купить и продать код удобным для Вас
                                                    способом оплаты Вы можете на специализированной ОТС
                                                    секции. https:/io/otc</p>
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Код пополнения</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={depositCode}
                                                    onChange={e => this.setState({ depositCode: e.target.value })}
                                                    required
                                                />
                                            </div>
                                            <div className="main-form__footer">
                                                {/*<span className="base-text">Ваш идентификатор: <span className="ident">SN35DE54425A</span></span>*/}
                                                <Button kind='secondary' type='submit' disabled={!depositCode} loading={loading}>Пополнить</Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="main-block">
                                <h2 className="operation-title">История пополнений</h2>
                                <HistoryDeposit operations={operations?.deposits} mantissa={2} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('deal__content', { active: activeTab === 'withdraw' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='KZT' />
                                <div className="bye-sell">
                                    <form className="main-form" onSubmit={this.onWithdraw}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <div className="main-form__input-wrap main-form__input-wrap_arrow">
                                                    <WithdrawalMethod/>
                                                </div>
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Сумма вывода</label>
                                                <div className="main-form__input-wrap">
                                                    <input
                                                        className="main-form__input"
                                                        type="number"
                                                        min={0}
                                                        max={profile?.kztBalance}
                                                        step={.01}
                                                        value={withdrawAmount}
                                                        onChange={e => this.setState({ withdrawAmount: e.target.value })}
                                                    />
                                                </div>
                                                <button
                                                    className="main-form__max"
                                                    type="button"
                                                    onClick={() => this.setState({ withdrawAmount: profile?.kztBalance })}
                                                >
                                                    MAX
                                                </button>
                                            </div>
                                            {profile?.otpEnabled && (
                                                <div className="main-form__field">
                                                    <label className="main-form__label" htmlFor="#">Код 2FA</label>
                                                    <input
                                                        className="main-form__input"
                                                        type="text"
                                                        value={otpCode}
                                                        onChange={e => this.setState({ otpCode: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                            )}
                                            {/*<div className="main-form__field"><label
                                                className="main-form__label" htmlFor="#">CFR</label>
                                                <div className="main-form__input-wrap"><input
                                                    className="main-form__input" type="text"/></div>
                                                <button className="main-form__max" type="button">MAX
                                                </button>
                                            </div>*/}
                                            <div className="main-form__footer">
                                                {/*<span className="base-text">Ваш идентификатор: <span
                                                    className="ident">SN35DE54425A</span></span>*/}
                                                <Button
                                                    kind="secondary"
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={!withdrawAmount || withdrawAmountNumber <= 0 || withdrawAmountNumber > parseFloat(profile?.kztBalance)}
                                                >
                                                    Вывести
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="main-block">
                                <h2 className="operation-title">История вывода</h2>
                                <HistoryWithdrawRub operations={operations?.withdraws} operationsExmoCode={operationsExmoCode}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
