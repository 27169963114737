import React from 'react';
import { Switch } from "react-router-dom";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../../stores";
import { Button } from "../../components/Button";
import { pd, processRequestError, tf } from "../../utils/utilities";
import { Api } from "../../utils/api";
import { OperationsType } from "../../utils/graphql";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { HistoryWithdraw } from "../../components/HistoryWithdraw";

interface IAssetsPartnerTabProps {
}

interface IAssetsPartnerTabState {
    withdrawAmount: string;
    loading: boolean;
    operations?: OperationsType;
    otpCode: string;
}

@observer
export class AssetsPartnerTab extends React.Component<IAssetsPartnerTabProps, IAssetsPartnerTabState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(Api)
    declare protected readonly api: Api;

    state: IAssetsPartnerTabState = {
        withdrawAmount: '',
        loading: false,
        otpCode: '',
    }

    componentDidMount() {
        this.loadOperations();
    }

    loadOperations = async () => {
        this.setState({ operations: await this.api.getOperations('partner') });
    }

    onWithdraw = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.api.withdrawPartner(this.state.withdrawAmount, this.state.otpCode);
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success('Средства переведены на рублёвый счет');
            this.setState({ withdrawAmount: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { withdrawAmount, loading, operations, otpCode } = this.state;
        const profile = this.authStore.profile;
        const withdrawAmountNumber = parseFloat(withdrawAmount);

        return (
            <div className="tabs__content active">
                <div className="main-content">
                    <div className="main-block">
                        <div className="balance-head">
                            <h2 className="operation-title">Вывод с партнерского счета</h2>
                            <span className="balance">{tf(profile?.partnerBalance)} RUB</span>
                            <span className="balance cold">0.00 RUB</span>
                        </div>
                        <div className="bye-sell">
                            <form className="main-form" onSubmit={this.onWithdraw}>
                                <div className="main-form__wrap">
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Способ вывода</label>
                                        <input className="main-form__input" type="text" disabled value="На рублёвый счет"/>
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Сумма вывода</label>
                                        <div className="main-form__input-wrap">
                                            <input
                                                className="main-form__input"
                                                type="number"
                                                min={0}
                                                max={profile?.partnerBalance}
                                                value={withdrawAmount}
                                                onChange={e => this.setState({ withdrawAmount: e.target.value })}
                                            />
                                        </div>
                                        <button className="main-form__max" type="button" onClick={() => this.setState({ withdrawAmount: profile?.partnerBalance })}>MAX</button>
                                    </div>
                                    {profile?.otpEnabled && (
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">Код 2FA</label>
                                            <input
                                                className="main-form__input"
                                                type="text"
                                                value={otpCode}
                                                onChange={e => this.setState({ otpCode: e.target.value })}
                                                required
                                            />
                                        </div>
                                    )}
                                    {/*<div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">CFR</label>
                                        <div className="main-form__input-wrap">
                                            <input className="main-form__input" type="text"/>
                                        </div>
                                        <button className="main-form__max" type="button">MAX</button>
                                    </div>*/}
                                    {/*<div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Сумма вывода</label>
                                        <input className="main-form__input" type="text"/>
                                    </div>*/}
                                    <div className="main-form__footer">
                                        {/*<span className="base-text">Ваш идентификатор: <span
                                            className="ident">SN35DE54425A</span></span>*/}
                                        <Button
                                            kind="secondary"
                                            type="submit"
                                            disabled={!withdrawAmount || withdrawAmountNumber <= 0 || withdrawAmountNumber > parseFloat(profile?.partnerBalance)}
                                            loading={loading}
                                        >
                                            Вывести
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="main-block">
                        <h2 className="operation-title">История вывода</h2>
                        <HistoryWithdraw operations={operations?.withdraws} mantissa={2} withoutDestination />
                    </div>
                </div>
            </div>
        )
    }
}