import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { OTCIndexTab } from "./otc/OTCIndexTab";
import { OTCAdsTab } from "./otc/OTCAdsTab";
import { OTCDealsTab } from "./otc/OTCDealsTab";
import { OTCChatsTab } from "./otc/OTCChatsTab";
import { OTCDealChat } from "./otc/OTCDealChat";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../stores";

interface IOTCPageProps {
}

interface IOTCPageState {
}

@observer
export class OTCPage extends React.Component<IOTCPageProps, IOTCPageState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    render() {
        return (
            <main className="main">
                <section className="otc-section">
                    <div className="container">
                        <div className="tabs">
                            <ul className="tabs__list">
                                <NavLink exact to='/otc' className="tabs__item">OTC</NavLink>
                                <NavLink to='/otc/ads' className="tabs__item">Мои объявления</NavLink>
                                <NavLink to='/otc/deals' className="tabs__item">Мои сделки {this.authStore.unreadMessages > 0 && <span className='unread-marker'>*</span>}</NavLink>
                                <NavLink to='/otc/chats' className="tabs__item">Мои чаты</NavLink>
                            </ul>
                            <Switch>
                                <Route exact path='/otc' component={OTCIndexTab} />
                                <Route path='/otc/ads' component={OTCAdsTab} />
                                <Route exact path='/otc/deals' component={OTCDealsTab} />
                                <Route path='/otc/deals/:did' component={OTCDealChat} />
                                <Route path='/otc/chats' component={OTCChatsTab} />
                                <Route path='/otc/chats/:cid' component={OTCChatsTab} />
                            </Switch>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}