        // Format and log the data Bunance
        // const candles = data.map(candle => ({
        //     openTime: candle[0],
        //     open: parseFloat(candle[1]),
        //     high: parseFloat(candle[2]),
        //     low: parseFloat(candle[3]),
        //     close: parseFloat(candle[4]),
        //     volume: parseFloat(candle[5]),
        //     closeTime: candle[6]
        // }));
        

const  getBinanceCandles = async (symbol, baseSymbol, interval, limit = 1000) => {
    // const url = `https://api.binance.com/api/v3/klines?symbol=${symbol}${baseSymbol}&interval=${interval}&limit=${limit}&startTime=${startTime}&endTime=${endTime}`;
    const url = `https://api.binance.com/api/v3/klines?symbol=${symbol}${baseSymbol}&interval=${interval}&limit=${limit}`;
    
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        
        const data = await response.json();
        
        return data;
    } catch (error) {
        console.error(`Error fetching Binance ${symbol}-${baseSymbol} candles:`, error);
    }
}


export const getDataCandles = async (symbol, baseSymbol, interval, pairs = 'BTCRUB', limit = 1000) => {
    let data;
    switch (pairs) {
        case 'BTCRUB':
            data = getBinanceCandles(symbol, baseSymbol, interval, limit);
            break
        case 'BTCUSDT':
            data = getBinanceCandles(symbol, baseSymbol, interval, limit);
            break
        case 'LTCUSDT':
            data = getBinanceCandles(symbol, baseSymbol, interval, limit);
            break
        case 'USDTRUB':
            data = getBinanceCandles(symbol, baseSymbol, interval, limit);
            break
        
    }
    return data;
}
  

