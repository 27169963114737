import React from 'react';
import { OtcDeal } from "../../utils/graphql";
import classNames from "classnames";
import { DateTime } from "luxon";
import { Link } from 'react-router-dom';
import { resolve } from "inversify-react";
import { Api } from "../../utils/api";

interface IOTCDealsTabProps {
}

interface IOTCDealsTabState {
    deals: OtcDeal[];
    status: null | boolean;
}

export class OTCDealsTab extends React.Component<IOTCDealsTabProps, IOTCDealsTabState> {
    private updateInterval: any;

    @resolve(Api)
    declare protected readonly api: Api;

    state: IOTCDealsTabState = {
        deals: [],
        status: null,
    }

    componentDidMount() {
        this.loadDeals();
        this.updateInterval = setInterval(this.loadDeals, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval);
    }

    loadDeals = async () => {
        this.setState({ deals: await this.api.getMyOtcDeals() });
    }

    render() {
        const { deals, status } = this.state;

        return (
            <div className="tabs__content active">
                <div className="deal">
                    <ul className="deal__list">
                        <li className={classNames('deal__item', { active: status === null })} onClick={() => this.setState({ status: null })}>Активные</li>
                        <li className={classNames('deal__item', { active: status === true })} onClick={() => this.setState({ status: true })}>Завершенные</li>
                        <li className={classNames('deal__item', { active: status === false })} onClick={() => this.setState({ status: false })}>Отмененные</li>
                    </ul>
                    <div className="deal__content active">
                        <div className="table deal-active scroll-wrap">
                            <div className="table__wrap">
                                <div className="table__head">
                                    <div className="table__field table__field_row">
                                        <span>ID</span>
                                        {/*<div className="table__nav">
                                            <button className="table__button table__button_top" type="button"/>
                                            <button className="table__button table__button_down" type="button"/>
                                        </div>*/}
                                    </div>
                                    <div className="table__field"><span>Дата и время</span></div>
                                    <div className="table__field"><span>Продавец</span></div>
                                    <div className="table__field"><span>Покупатель</span></div>
                                    <div className="table__field"><span>Статус</span></div>
                                    <div className="table__field"><span>Сумма</span></div>
                                    <div className="table__field"><span>Метод оплаты</span></div>
                                    <div className="table__field"><span>Цена</span></div>
                                    <div className="table__field"><span>Комиссия</span></div>
                                    <div className="table__field"><span>На руки</span></div>
                                </div>
                                <div className="table__body scroll-wrap">
                                    {deals.filter(d => d.status === status).map(d => (
                                        <Link to={`/otc/deals/${d.id}`} className="table__row" key={d.id}>
                                            <div className="table__field"><span>{d.id}</span> {d.hasUnread && <span className='unread-marker'>*</span>}</div>
                                            <div className="table__field"><span>{DateTime.fromISO(d.datetime).toLocaleString(DateTime.DATETIME_SHORT)}</span></div>
                                            <div className="table__field"><span>{d.ad.action === 'SELL' ? d.ad.user.name : d.user.name}</span></div>
                                            <div className="table__field"><span>{d.ad.action === 'BUY' ? d.ad.user.name : d.user.name}</span></div>
                                            <div className={classNames('table__field', { success: d.status === null || d.status === true, warning: d.status === false })}>
                                                <span>{d.status === null ? 'Активный' : d.status === true ? 'Завершенный' : 'Отменен'}</span>
                                            </div>
                                            <div className="table__field table__field_col">
                                                <span className="warning">{d.amount} {d.ad.currency}</span>
                                                {/*<span className="info-text">(516.51 CFR)</span>*/}
                                            </div>
                                            <div className="table__field table__field_col">
                                                <span>{d.ad.paymentMethod}</span>
                                            </div>
                                            <div className="table__field table__field_col">
                                                <span className="warning">{d.amount * (1 + parseFloat(d.ad.priceExtraPercent) / 100)}</span>
                                                {/*<span className="info-text">(516.51 CFR)</span>*/}
                                            </div>
                                            <div className="table__field"><span>+{d.ad.priceExtraPercent}% комиссия</span></div>
                                            <div className="table__field table__field_col">
                                                <span className="warning">{d.amount} {d.ad.currency}</span>
                                                {/*<span className="info-text">(516.51 CFR)</span>*/}
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
