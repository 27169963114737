import * as stores from './';

import { createBrowserHistory, History } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import { wrapHistory } from "oaf-react-router";
import { Container } from 'inversify';
import store from 'store';
import { Api } from "../utils/api";
import { makeObservable } from "mobx";


export class RootStore {
    public historyStore: History;
    public routerStore: stores.RouterStore;
    public modalStore: stores.ModalStore;
    public authStore: stores.AuthStore;
    public infoStore: stores.InfoStore;
    public ordersStore: stores.OrdersStore;
    public coinStore: stores.CoinStore;
    public coinBaze: stores.CoinBaze;

    public container: Container;

    public constructor() {
        const browserHistory = createBrowserHistory();
        wrapHistory(browserHistory, {
            smoothScroll: true,
            primaryFocusTarget: 'body',
        });

        this.routerStore = new stores.RouterStore();
        makeObservable(this.routerStore);

        this.historyStore = syncHistoryWithStore(browserHistory, this.routerStore);

        this.authStore = new stores.AuthStore(this);
        this.modalStore = new stores.ModalStore(this);
        this.infoStore = new stores.InfoStore(this);
        this.ordersStore = new stores.OrdersStore(this);
        this.coinStore = new stores.CoinStore(this);
        this.coinBaze = new stores.CoinBaze(this);

        this.container = new Container();
        this.container.bind(stores.RouterStore).toConstantValue(this.routerStore);
        this.container.bind(stores.HistoryStore).toConstantValue(this.historyStore);
        this.container.bind(stores.AuthStore).toConstantValue(this.authStore);
        this.container.bind(stores.ModalStore).toConstantValue(this.modalStore);
        this.container.bind(stores.InfoStore).toConstantValue(this.infoStore);
        this.container.bind(stores.OrdersStore).toConstantValue(this.ordersStore);
        this.container.bind(stores.CoinStore).toConstantValue(this.coinStore);
        this.container.bind(stores.CoinBaze).toConstantValue(this.coinBaze);
        this.container.bind(Api).toDynamicValue(() => this.api);
    }

    get api(): Api {
        const token = store.get('token');
        return new Api(token);
    }
}


