import React from 'react';
import { Switch } from "react-router-dom";
import { HistoryDeposit } from "../../components/HistoryDeposit";
import { HistoryWithdraw } from "../../components/HistoryWithdraw";
import classNames from "classnames";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../../stores";
import { pd, processRequestError, tf } from "../../utils/utilities";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import { Api } from "../../utils/api";
import { OperationsType } from "../../utils/graphql";
import { DateTime } from "luxon";
import { Button } from "../../components/Button";
import BalanceLine from '../../components/active/BalanceLine';

interface IAssetsBtcTabProps {
}

interface IAssetsBtcTabState {
    activeTab: 'deposit' | 'withdraw';
    operations?: OperationsType;
    loading: boolean;
    withdrawAmount: string;
    withdrawAddress: string;
    otpCode: string;
}

@observer
export class AssetsBtcTab extends React.Component<IAssetsBtcTabProps, IAssetsBtcTabState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(Api)
    declare protected readonly api: Api;

    state: IAssetsBtcTabState = {
        activeTab: 'deposit',
        loading: false,
        withdrawAmount: '',
        withdrawAddress: '',
        otpCode: '',
    }

    componentDidMount() {
        this.loadOperations();
    }

    loadOperations = async () => {
        this.setState({ operations: await this.api.getOperations('btc') });
    }

    onWithdraw = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.api.withdrawBtc(this.state.withdrawAmount, this.state.withdrawAddress, this.state.otpCode);
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success('Заявка на вывод была создана');
            this.setState({ withdrawAmount: '', withdrawAddress: '', otpCode: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { activeTab, operations, loading, withdrawAddress, withdrawAmount, otpCode } = this.state;
        const profile = this.authStore.profile;
        const withdrawAmountNumber = parseFloat(withdrawAmount);

        return (
            <div className="tabs__content active">
                <div className="deal js-deal-tabs">
                    <ul className="deal__list">
                        <li className={classNames('deal__item', { active: activeTab === 'deposit' })} onClick={() => this.setState({ activeTab: 'deposit' })}>Пополнение</li>
                        <li className={classNames('deal__item', { active: activeTab === 'withdraw' })} onClick={() => this.setState({ activeTab: 'withdraw' })}>Вывод</li>
                    </ul>
                    <div className={classNames('deal__content', { active: activeTab === 'deposit' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='BTC' mantissa={8}/>
                                <div className="program-wrap">
                                    <div className="program">
                                        <div className="program__wrap">
                                            <span className="program__number">1</span>
                                            <div className="program__info">
                                                <p className="base-text">Используйте Ваш локальный
                                                    кошелек, мобильное приложение или онлайн сервис для
                                                    оплаты.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="program">
                                        <div className="program__wrap"><span
                                            className="program__number">2</span>
                                            <div className="program__info">
                                                <p className="base-text">Пожалуйста скопируйте адрес в
                                                    Ваш кошелек, введите сумму, которую Вы хотите
                                                    зачислить и подтвердите зачисление.</p>
                                                <div className="program__qr-wrap">
                                                    <div className="program__qr">
                                                        {profile && <QRCode value={profile?.btcAddress} size={190} />}
                                                        {/*<img src={require('../../images/qr.jpg')} alt=""/>*/}
                                                    </div>
                                                    <div className="program__link">
                                                        <span className="program__link-title">Минимальная сумма депозита: 0.0002 BTC</span>
                                                        <div className="program__link-wrap">
                                                            <p className="program__link-text">{profile?.btcAddress}</p>
                                                            <button className="program__link-copy" type="button" onClick={() => { copy(profile?.btcAddress); toast.success('Адрес скопирован') }}>
                                                                <svg>
                                                                    <use href="#copy"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <p className="base-text">Или отсканируйте QR код
                                                            для оплаты из мобильного приложения.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="program">
                                        <div className="program__wrap">
                                            <span className="program__number">3</span>
                                            <div className="program__info">
                                                <p className="base-text">Как только Вы закончите
                                                    отправку, Вы можете проверить статус Вашего нового
                                                    депозита ниже.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-block">
                                <h2 className="operation-title">История пополнений</h2>
                                <HistoryDeposit operations={operations?.deposits} mantissa={8} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('deal__content', { active: activeTab === 'withdraw' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='BTC' />
                                <div className="program-wrap">
                                    <div className="program">
                                        <div className="program__wrap">
                                            <span className="program__number">1</span>
                                            <div className="program__info">
                                                <p className="base-text">Пожалуйста введите адрес,
                                                    сумму, код подтверждения из СМС / Google
                                                    Authenticator, затем нажмите "Отправить". Заявка
                                                    будет подтверждена в течение 30 минут.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bye-sell">
                                    <form className="main-form" onSubmit={this.onWithdraw}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Адрес вывода</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={withdrawAddress}
                                                    onChange={e => this.setState({ withdrawAddress: e.target.value })}
                                                    required
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Сумма вывода</label>
                                                <div className="main-form__input-wrap">
                                                    <input
                                                        className="main-form__input"
                                                        type="number"
                                                        min={0}
                                                        max={profile?.btcBalance}
                                                        step={.00000001}
                                                        value={withdrawAmount}
                                                        onChange={e => this.setState({ withdrawAmount: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                                <button className="main-form__max" type="button" onClick={() => this.setState({ withdrawAmount: profile?.btcBalance })}>MAX</button>
                                            </div>
                                            {profile?.otpEnabled && (
                                                <div className="main-form__field">
                                                    <label className="main-form__label" htmlFor="#">Код 2FA</label>
                                                    <input
                                                        className="main-form__input"
                                                        type="text"
                                                        value={otpCode}
                                                        onChange={e => this.setState({ otpCode: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                            )}
                                            <div className="main-form__footer">
                                                <p className="base-text red">Внимание! Для Bech32
                                                    адресов (начинаются с bc1) комиссия на вывод всегда
                                                    0.0004 BTC, вне зависимости от объёма.</p>
                                                <Button
                                                    kind="secondary"
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={!withdrawAddress || !withdrawAmount || withdrawAmountNumber <= 0 || withdrawAmountNumber > parseFloat(profile?.btcBalance)}
                                                >
                                                    Вывести
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="main-block">
                                <h2 className="operation-title">История вывода</h2>
                                <HistoryWithdraw operations={operations?.withdraws} mantissa={8} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}