import React from 'react';
import { Modal } from "../components/Modal";
import { Modals, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { pd, processRequestError } from "../utils/utilities";
import { Button } from "../components/Button";
import { toast } from "react-toastify";
import { AuthStore } from "../stores";
import _ from "lodash";

interface ISignInModalProps {
}

interface ISignInModalState {
    email: string;
    password: string;
    has2fa: boolean;
    otpCode: string;
    loading: boolean;
}

@observer
export class SignInModal extends React.Component<ISignInModalProps, ISignInModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: ISignInModalState = {
        email: '',
        password: '',
        has2fa: false,
        otpCode: '',
        loading: false,
    }

    onSubmit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.tokenAuth(this.state.email, this.state.password, this.state.otpCode);
            toast.success('Вход выполнен успешно');
            this.modalStore.hideModals();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    checkHas2FA = _.debounce(async () => {
        this.setState({ loading: true });
        try {
            this.setState({ has2fa: await this.authStore.has2FA(this.state.email) });
        } finally {
            this.setState({ loading: false });
        }
    }, 300);

    render() {
        const { email, password, has2fa, otpCode, loading } = this.state;

        return (
            <Modal modalKey={Modals.SignIn} onShow={this.checkHas2FA}>
                <form className="main-form" onSubmit={this.onSubmit}>
                    <div className="main-form__wrap">
                        <h3 className="main-form__title">Вход</h3>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => { this.setState({ email: e.target.value }); this.checkHas2FA() }}
                            />
                        </div>
                        <div className="main-form__field main-form__field_col">
                            <input
                                className="main-form__input"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                            <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.Recover)}>Забыли пароль?</button>
                        </div>
                        {has2fa && (
                            <div className="main-form__field">
                                <input
                                    className="main-form__input"
                                    type="text"
                                    placeholder="Код 2FA"
                                    value={otpCode}
                                    onChange={e => this.setState({ otpCode: e.target.value })}
                                />
                            </div>
                        )}
                        <Button kind='primary' type='submit' loading={loading} disabled={!email || !password || has2fa && otpCode.length !== 6}>Вход</Button>
                        <div className="main-form__nav">
                            <span className="main-form__text">Нет аккаунта?</span>
                            <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Регистрация</button>
                        </div>
                    </div>
                </form>
            </Modal>
        )
    }
}
