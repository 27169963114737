import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore, ModalStore } from "../stores";
import { Modals } from "../stores/ModalStore";

interface IHeaderProps {
}

interface IHeaderState {
    mobileNavOpen: boolean;
}

@observer
export class Header extends React.Component<IHeaderProps, IHeaderState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;

    state: IHeaderState = {
        mobileNavOpen: false,
    }

    render() {
        const { mobileNavOpen } = this.state;

        return (
            <header className="header">
                <div className="container">
                    <Link className="logo" to="/">
                            <div className="bg-logo"><img src={require('../images/Logo_1200.gif')} alt=""/></div>
                    </Link>
                    <nav className={`nav ${mobileNavOpen && 'active'}`}>
                        <ul>
                            <li><NavLink to="/exchange">Биржа</NavLink></li>
                            <li><NavLink to="/assets">Активы</NavLink></li>
                           
                            <li><NavLink to="/otc">OTC {this.authStore.unreadMessages > 0 && <span className='unread-marker'>*</span>}</NavLink></li>
                           
                     
                            {this.authStore.token && (
                                <>
                                    <li><NavLink to="/program">Партнер. программа</NavLink></li>
                                </>
                            )}
                        </ul>
                        {this.authStore.token ? (
                            <div className="user-nav">
                                <Link className="signin" to='/profile'>Профиль</Link>
                            </div>
                        ) : (
                            <div className="user-nav">
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>Вход</button>
                                <span>/</span>
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Регистрация</button>
                            </div>
                        )}
                    </nav>
                    <div className="burger" onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}><span/><span/><span/></div>
                </div>
            </header>
        )
    }
}




/*
 <div className="bg-logo"><img src={require('../images/Logo_1200.gif')} alt=""/></div>


    <div className="bg-logo"><img src={require('../images/logo.png')} alt=""/></div>
  <div className="contact">
                            <span className="contact__name">TOP EXCHANGE</span>
                            <span className="base-text">Биржа криптовалюты и токенов</span>
                        </div>
*/