export const ERROR_MESSAGES = {
    invalid_email: 'Некорректный адрес электронной почты',
    invalid_credentials: 'Неверный e-mail и/или пароль',
    invalid_otp_code: 'Неверный код 2FA',
    email_taken: 'E-mail уже зарегистрирован',
    email_not_registered: 'E-mail не зарегистрирован',
    invalid_old_password: 'Неверный старый пароль',
    invalid_phone: 'Некорректный номер телефона',
    code_not_found: 'Неверный код пополнения',
    insufficient_balance: 'Недостаточно средств на счету',
    insufficient_funds: 'Недостаточно средств',
    invalid_amount: 'Некорректная сумма',
    invalid_address: 'Неверный адрес',
    invalid_sums: 'Некорректные минимальная и максимальная суммы',
    invalid_extra: 'Некорректная цена',
    invalid_action: 'Некорректное направление объявления',
    ad_not_found: 'Объявление не найдено',
    ad_same_user: 'Вы не можете произвести сделку с самим собой',
    deal_not_found: 'Сделка не найдена',
    OrderBookIsNull: 'Нет ордеров в стакане',
}

export const API_PROTO = process.env.MAIN_API_PROTO;
export const WS_PROTO = process.env.MAIN_WS_PROTO;
export const API_HOST = process.env.MAIN_API_HOST;
export const WS_HOST = process.env.MAIN_WS_HOST;

//export const API_HOST = '127.0.0.1:8000';
//export const WS_HOST = '127.0.0.1:8000';


