import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';

import { rootStore } from '../Application';

const WithdrawalMethod = () => {
    const textOptionsWithdrawal = ['Вывод с MaxCode', 'Вывод с MaxCode', 'Вывод с ExmoCode'];
    const [showOptionsWithdrawal, setShowOptionsWithdrawal] = useState(false);
    const [selectTitleWithdrawal, setSelectTitleWithdrawal] = useState(textOptionsWithdrawal[0]);

    const handleTitleSelect = () => {
        if (showOptionsWithdrawal) {
            setShowOptionsWithdrawal(false);
          } else {
            setShowOptionsWithdrawal(true);
          }
    };

    
    const handleLabelSelect = (evt) => {
        setSelectTitleWithdrawal(evt.target.textContent);
        setShowOptionsWithdrawal(() => (false));
    }

    const handleChange = (event) => {
        rootStore.coinBaze.setSelectWithdrawal(event.target.value);
    };

    return (
        (<div className="main-form__field">
            <label className="main-form__label" htmlFor="#">Способ вывода</label>
            <div className={`deposit-select ${showOptionsWithdrawal ? `deposit-select--active` : ``}`}>
                <div onClick={handleTitleSelect}  className="deposit-select__title">{selectTitleWithdrawal}</div>
                <div className="deposit-select__list-options list-deposit">
                {['MaxCode','MaxCode', 'ExmoCode'].map((element, index) => {
                    return (
                        (<React.Fragment key={`${element}-${index}`}>
                            <input onChange={handleChange} value={element} id={`withdrawalSelect${index}`} className="list-deposit__element-input" type="radio" name="singleWithdrawalSelect" />
                            <label onClick={handleLabelSelect} htmlFor={`withdrawalSelect${index}`} className="list-deposit__element-label">{textOptionsWithdrawal[index]}</label>
                        </React.Fragment>)
                    );
                })}
                </div>
            </div>
        </div>)
    );
};

export default observer(WithdrawalMethod);