import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { RootStore } from "./stores";
import { ToastContainer } from "react-toastify";
import { Provider } from "inversify-react";
import { IndexPage } from "./pages/IndexPage";
import { Modals } from "./modals";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { ProfilePage } from "./pages/ProfilePage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ProgramPage } from "./pages/ProgramPage";
import { AssetsPage } from "./pages/AssetsPage";
import { SVGSprite } from "./components/SVGSprite";
import { OTCPage } from "./pages/OTCPage";
import { observer } from "mobx-react";
import { ExchangePage } from "./pages/ExchangePage";

export const rootStore = new RootStore();
const container = rootStore.container

@observer
class Application extends React.Component {
    render() {
        if (!rootStore.authStore.initialized)
            return 'Loading...';

        return (
            <Provider container={container}>
                <Router history={rootStore.historyStore}>
                    <ToastContainer/>

                    <Header />
                    <Switch>
                        <Route exact path='/' component={IndexPage} />
                        <ProtectedRoute exact path='/profile' component={ProfilePage} />
                        <ProtectedRoute exact path='/program' component={ProgramPage} />
                        <ProtectedRoute path='/assets' component={AssetsPage} />
                        <ProtectedRoute path='/otc' component={OTCPage} />
                        <ProtectedRoute path='/exchange' component={ExchangePage} />
                    </Switch>
                    <Footer />

                    <Modals />

                    <SVGSprite />
                </Router>
            </Provider>
        );
    }
}

export default Application;
