import React, {FC, useState, useEffect} from 'react';
import classNames from "classnames";
import {observer} from 'mobx-react-lite';
import {rootStore} from "../../Application";

import {ExchangeOrdersType} from "../../utils/graphql";
import {useInterval} from "../../utils/interval";


interface IOrderBookProps {
    symbol: string;
    coinSymbol: string;
    baseSymbol: string;
}


const OrderBook: FC<IOrderBookProps> = ({symbol,coinSymbol,baseSymbol}) => {

    const [limit, setLimit] = useState<number>(20)
    const [loading, setLoading] = useState<boolean>(false)
    const [itemsSell, setItemsSell] = useState<ExchangeOrdersType[]>()
    const [itemsBuy, setItemsBuy] = useState<ExchangeOrdersType[]>()

    // @ts-ignore
    useEffect(async () => {
        await loadingOrderBook();
    }, []);

    useInterval(async () => {
         await loadingOrderBook()
    }, 1000 * 2);

    // @ts-ignore
    useEffect(async () => {
        await loadingOrderBook()
    }, [symbol]);


    const setItems = async (one,direct) => {
            console.log(one,direct)
            rootStore.ordersStore.setSelectData(one,direct)
    }
    const loadingOrderBook = async () => {
        await rootStore.ordersStore.getOrdersBook(symbol, limit)
        setItemsBuy(rootStore.ordersStore.buy)
        setItemsSell(rootStore.ordersStore.sell)
    }



    return (
        <div className="table-wrap">
            <div className="table glass table-border  scroll-wrap">
                <div className="table__wrap" >
                    <h3 className="table__title">Продажа {coinSymbol}</h3>
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>Курс ({baseSymbol})</span>
                        </div>
                        <div className="table__field"><span>Кол-во ({coinSymbol})</span></div>
                        {/*<div className="table__field"><span>Тип цены</span></div>
                        <div className="table__field"><span>CFR</span></div>*/}
                        <div className="table__field"><span>Всего ({baseSymbol})</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {itemsSell?.map((one, key) => (
                            <div className="table__row" style={{cursor:"pointer"}} key={key} onClick={() => setItems(one,"BUY")}>
                                <div className="table__field warning"><span>{one.price}</span></div>
                                <div className="table__field"><span>{one.amounts}</span></div>
                                {/*<div className="table__field"><span>Binance +2.4%</span></div>
                                <div className="table__field"><span>889 936</span></div>*/}
                                <div className="table__field"><span>{one.turnovers}</span></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="table glass table-border  scroll-wrap">
                <div className="table__wrap">
                    <h3 className="table__title">Покупка {coinSymbol}</h3>
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>Курс ({baseSymbol})</span>
                        </div>
                        <div className="table__field"><span>Кол-во ({coinSymbol})</span></div>
                        <div className="table__field"><span>Всего ({baseSymbol})</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {itemsBuy?.map((one,key) => (
                            <div className="table__row" key={key}  style={{cursor:"pointer"}}  onClick={() => setItems(one,"SELL")}>
                                <div className="table__field success"><span>{one.price}</span></div>
                                <div className="table__field"><span>{one.amounts}</span></div>
                                <div className="table__field"><span>{one.turnovers}</span></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OrderBook
