import React, {FC, useState, useRef, useEffect} from 'react';
import { createChart, ColorType } from 'lightweight-charts';
import {observer} from 'mobx-react-lite';

interface IChartComponentProps {
    data: any;
    timeFrameSelect: string;
}

interface IColorsProps {
        backgroundColor: string;
        textColor: string;
        fontSize: number;
        fontFamily: string;
        attributionLogo: boolean;
};

interface IDataPairCandle {
    time: string;
    low: number;
    high: number;
    open: number;
    close: number;
    volume: number;
}

interface IDataPairsCandle extends Array<IDataPairCandle> {};

interface IDataPairHistogram {
    time: string;
    value: number;
}

interface IDataPairsHistogram extends Array<IDataPairHistogram> {};

const adapterBinanceToChartCandle = (data) => {
    const result: IDataPairsCandle = data.map(function(item) {
        return {
            time: Math.floor(new Date(item[0]).getTime() / 1000),
            open: parseFloat(item[1]),
            high: parseFloat(item[2]),
            low: parseFloat(item[3]),
            close: parseFloat(item[4]),
            volume: parseFloat(item[5]),
        };
    });

    return result;
};

const ChartComponent: FC<IChartComponentProps> = (props) => {
    const {
        data,
        timeFrameSelect,
    } = props;

    const chartContainerRef = useRef();
    const colors: IColorsProps = {
        backgroundColor: '#100f14',
        textColor: '#919294',
        fontSize: 12,
        fontFamily: 'SF Pro Display',
        attributionLogo: false,
    };

    const {backgroundColor, textColor, fontSize, fontFamily, attributionLogo} = colors;

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                autoSize: true,
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                    fontSize,
                    fontFamily,
                    attributionLogo,
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: true,
                },
                width: chartContainerRef.current.clientWidth,
                height: 300,
                grid: {
                    vertLines: {
                        color: '#292a2f',
                    },
                    horzLines: {
                        color: '#292a2f',
                    },
                },
                
                
                
            });
            chart.timeScale().fitContent();
            
            chart.timeScale().applyOptions({
                barSpacing: 10,
            });


            const candlestickSeries = chart.addCandlestickSeries({
                upColor: '#20b16c', downColor: '#ef444a', borderVisible: false,
                wickUpColor: '#20b16c', wickDownColor: '#ef444a',
            });
            candlestickSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.2,
                },
            });
            candlestickSeries.setData(adapterBinanceToChartCandle(data));

            const histogramSeries  = chart.addHistogramSeries({priceScaleId: ""});
            histogramSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.8,
                    bottom: 0,
                }
            });
            const volumeData = adapterBinanceToChartCandle(data).map((kline) => ({
                time: kline.time,
                value: kline.volume,
                color: kline.close > kline.open ? "#175138" : "#6b262b",
            }));
            histogramSeries.setData((volumeData));

            window.addEventListener('resize', handleResize);

            

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [data, timeFrameSelect]
    );

    return (
        <div
            ref={chartContainerRef}
        />
    );
};


export default observer(ChartComponent);
