import React from 'react';

interface ISVGSpriteProps {
}

interface ISVGSpriteState {
}

export class SVGSprite extends React.Component<ISVGSpriteProps, ISVGSpriteState> {
    render() {
        return (
            <div className="svg-wrap">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 20 20" id="send">
                        <path
                            d="M17.3333 2C17.1565 2 16.987 2.07024 16.8619 2.19526C16.7369 2.32029 16.6667 2.48986 16.6667 2.66667C16.6667 2.84348 16.7369 3.01305 16.8619 3.13807C16.987 3.2631 17.1565 3.33333 17.3333 3.33333C17.5101 3.33333 17.6797 3.2631 17.8047 3.13807C17.9298 3.01305 18 2.84348 18 2.66667C18 2.48986 17.9298 2.32029 17.8047 2.19526C17.6797 2.07024 17.5101 2 17.3333 2ZM2.66667 6.66667C2.48986 6.66667 2.32029 6.7369 2.19526 6.86193C2.07024 6.98695 2 7.15652 2 7.33333C2 7.51014 2.07024 7.67971 2.19526 7.80474C2.32029 7.92976 2.48986 8 2.66667 8C2.84348 8 3.01305 7.92976 3.13807 7.80474C3.2631 7.67971 3.33333 7.51014 3.33333 7.33333C3.33333 7.15652 3.2631 6.98695 3.13807 6.86193C3.01305 6.7369 2.84348 6.66667 2.66667 6.66667ZM12.6667 16.6667C12.4899 16.6667 12.3203 16.7369 12.1953 16.8619C12.0702 16.987 12 17.1565 12 17.3333C12 17.5101 12.0702 17.6797 12.1953 17.8047C12.3203 17.9298 12.4899 18 12.6667 18C12.8435 18 13.013 17.9298 13.1381 17.8047C13.2631 17.6797 13.3333 17.5101 13.3333 17.3333C13.3333 17.1565 13.2631 16.987 13.1381 16.8619C13.013 16.7369 12.8435 16.6667 12.6667 16.6667Z"/>
                        <path
                            d="M17.5503 2.44935L17.1437 2.02802L2.43365 6.70668L2.27832 7.86801L6.77632 11.4367L15.5817 4.41801L8.56299 13.2233L12.1317 17.7213L13.293 17.566L17.9717 2.85602L17.5503 2.44935Z"/>
                    </symbol>
                    <symbol viewBox="0 0 20 20" id="file">
                        <path
                            d="M13.5329 2.01172C12.5507 2.01172 11.5694 2.40085 10.8337 3.17708L5.49511 8.80859C3.49902 10.915 3.49902 14.3011 5.49511 16.4076C5.55665 16.4724 5.6306 16.5242 5.71256 16.5599C5.71386 16.5603 5.71516 16.5608 5.71647 16.5612C7.72756 18.467 10.8646 18.4457 12.7959 16.4076L16.5511 12.4453C16.6147 12.3825 16.665 12.3075 16.699 12.2248C16.733 12.1421 16.75 12.0535 16.7491 11.9641C16.7481 11.8747 16.7292 11.7864 16.6935 11.7045C16.6577 11.6226 16.6058 11.5487 16.5409 11.4872C16.4761 11.4257 16.3995 11.3779 16.3157 11.3466C16.232 11.3153 16.1428 11.3012 16.0535 11.3051C15.9642 11.3089 15.8766 11.3307 15.7959 11.3691C15.7152 11.4075 15.643 11.4618 15.5837 11.5286L11.8284 15.4909C10.3273 17.0751 7.96505 17.0751 6.46386 15.4909C4.96262 13.9067 4.96262 11.3095 6.46386 9.72526L11.8011 4.09375C12.7776 3.06356 14.2895 3.06356 15.2659 4.09375C16.2423 5.12384 16.2423 6.82408 15.2659 7.85417L10.4079 12.9805C9.95527 13.4576 9.2946 13.4573 8.84277 12.9805C8.39093 12.5036 8.39093 11.7008 8.84277 11.224L12.7503 7.10026C12.8139 7.03742 12.8642 6.96243 12.8982 6.87976C12.9322 6.79709 12.9492 6.70843 12.9483 6.61904C12.9473 6.52966 12.9284 6.44137 12.8927 6.35944C12.8569 6.27751 12.8051 6.20361 12.7402 6.14213C12.6753 6.08064 12.5987 6.03284 12.5149 6.00155C12.4312 5.97026 12.342 5.95613 12.2527 5.96C12.1634 5.96387 12.0758 5.98566 11.9951 6.02407C11.9144 6.06248 11.8422 6.11673 11.7829 6.18359L7.87402 10.3073C6.92719 11.3065 6.92719 12.898 7.87402 13.8971C7.93722 13.9636 8.01346 14.0164 8.09798 14.0521C9.0584 14.8544 10.4931 14.8272 11.3753 13.8971L16.2334 8.77083C17.6443 7.28232 17.6646 4.95281 16.3701 3.39323C16.3389 3.31303 16.2925 3.23963 16.2334 3.17708C15.4976 2.40085 14.5151 2.01172 13.5329 2.01172Z"/>
                    </symbol>
                    <symbol viewBox="0 0 24 24" id="copy">
                        <path
                            d="M8.80019 1.6C7.91619 1.6 7.2002 2.316 7.2002 3.2V16C7.2002 16.884 7.91619 17.6 8.80019 17.6H19.2002C20.0842 17.6 20.8002 16.884 20.8002 16V6.8C20.8002 6.588 20.7162 6.38477 20.5658 6.23437L16.1658 1.83437C16.0154 1.68397 15.8122 1.6 15.6002 1.6H8.80019ZM15.2002 3.12344L19.2768 7.2H16.0002C15.5586 7.2 15.2002 6.8416 15.2002 6.4V3.12344ZM4.8002 5.6C3.9162 5.6 3.2002 6.316 3.2002 7.2V20C3.2002 20.884 3.9162 21.6 4.8002 21.6H15.2002C16.0842 21.6 16.8002 20.884 16.8002 20V19.2H8.80019C7.03539 19.2 5.6002 17.7648 5.6002 16V5.6H4.8002Z"/>
                    </symbol>
                </svg>
            </div>
        )
    }
}