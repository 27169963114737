import React  from 'react';
import classNames from "classnames";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    kind: 'primary' | 'secondary' | 'tertiary' | 'fourthly';
    send?: boolean;
    small?: boolean;
}

interface IButtonState {
}

export class Button extends React.Component<IButtonProps, IButtonState> {
    render() {
        const { loading, kind, send, small, disabled, children, ...props } = this.props;

        const btnClass = classNames({
            btn: true,
            [kind]: true,
            send,
            small,
            loading,
        });

        return (
            <button disabled={loading || disabled} className={btnClass} {...props}>
                {loading && <AiOutlineLoading3Quarters className='icon-spin' />} {children}
            </button>
        )
    }
}