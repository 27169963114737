import { RootStore } from "./";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import store from 'store';
import { injectable } from "inversify";
import { CoinType, BalanceExchangeType } from "../utils/graphql";

@injectable()
export class CoinStore {

    @observable  coins: string[];
    @observable  baseSymbol: string[];
    @observable coin: CoinType;
    @observable balances: BalanceExchangeType[];
    @observable balanceBuy: BalanceExchangeType;
    @observable balanceSell: BalanceExchangeType;
    @observable balanceBuySymbol: string;
    @observable balanceSellSymbol: string;

    get api() {
        return this.rootStore.api;
    }

    public constructor(protected rootStore: RootStore) {
        makeObservable(this);
    }

    @action async getBalancesAll() {
        const result = await this.api.sdk.getBalancesAll()

        runInAction(() => {
                this.balances = result.getBalancesAll;
            }
        );
    }

    @action async getBalancesOne(symbol:string) {
        const result = await this.api.sdk.getBalancesOne({symbol})
    
        runInAction(() => {
                this.balanceSell = result.getBalancesOne;
                this.balanceSellSymbol = symbol;
            }
        );
    }

    @action async getBalancesBuyOne(symbol:string) {
        const result = await this.api.sdk.getBalancesOne({symbol})
        runInAction(() => {
                this.balanceBuy = result.getBalancesOne;
                this.balanceBuySymbol = symbol;
            }
        );
    }

    @action async getCoins(baseSymbol:string) {
        const coins = await this.api.sdk.getCoins({baseSymbol})
        runInAction(() => {
                this.coins = coins.coins;
            }
        );
    }

    @action async getBaseSymbols() {
        const symbols = await this.api.sdk.getBaseSymbol()
        runInAction(() => {
                this.baseSymbol = symbols.basesymbol;
            }
        );

        return symbols.basesymbol;
    }

    @action async getCoin(symbol:string) {
        const symbols = await this.api.sdk.getCoin({symbol})
        runInAction(() => {
                this.coin = symbols.coin;
            }
        );

        return symbols.coin;
    }

}
