import React from 'react';
import _ from "lodash";
import classNames from "classnames";
import {observer} from "mobx-react";
import {resolve} from "inversify-react";
import {AuthStore, CoinStore} from "../stores";
import {tf, normalizeVolume, PrecisionCount} from "../utils/utilities";
import HistoryOrders from "../components/exchange/HistoryOrders";
import {BalanceExchangeType, CoinType} from "../utils/graphql";
import OrderBook from "../components/exchange/OrderBook";
import FormBuy from "../components/exchange/FormBuy";
import FormSell from "../components/exchange/FormSell";

interface IExchangePageProps {
}

interface IExchangePageState {
    pair: string;
    priceType: 'market' | 'limit' | 'moex';
    baseSymbols: string[];
    coins: string[];
    currentBaseSymbol: string;
    currentCoin?: CoinType;
    balanses?: BalanceExchangeType[];
    inputBuy: number;
    inputTurnoverBuy: number;
    currentRate: number;
}

@observer
export class ExchangePage extends React.Component<IExchangePageProps, IExchangePageState> {
    state: IExchangePageState = {
        pair: '',
        priceType: 'market',
        baseSymbols: [],
        balanses: [],
        coins: [],
        currentBaseSymbol: "",
        inputBuy: 0.0001,
        currentRate: 0.1,
        inputTurnoverBuy: 0.0001
    }

    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    @resolve(CoinStore)
    declare protected readonly coinStore: CoinStore;


    async componentDidMount() {
        try {
            this.setState({baseSymbols: await this.coinStore.getBaseSymbols()});
            this.setState({currentBaseSymbol: this.state.baseSymbols[0]});
                 
            await this.coinStore.getCoins(this.state.currentBaseSymbol)
            this.setState({coins: this.coinStore.coins})
            this.setState({pair: this.coinStore.coins[0]})

            await this.onGetCoin()
        } finally {

        }
    }

    onGetCoin = async () => {
        await this.coinStore.getCoin(this.state.pair)
        await this.coinStore.getBalancesAll()

        this.setState({balanses: this.coinStore.balances})
        this.setState({currentCoin: this.coinStore.coin})

        this.setState({currentRate: Number(tf(this.coinStore.coin.rubRate, this.coinStore.coin.baseCoinScale))})
        this.setState({inputTurnoverBuy: Number(tf(this.state.currentRate * this.state.inputBuy, this.coinStore.coin.baseCoinScale))})
    }

       onGetBalance(symbol:string):BalanceExchangeType {
        let findOne:BalanceExchangeType =  this.state.balanses.find((one:BalanceExchangeType)=>one.symbol==symbol)
        if (findOne) {
            return findOne
        }
    }

    validateOutput = (newValue) => {
        let min = 0;
        let max = 100000000

        if (newValue !== true && isNaN(newValue)) {
            //console.log("55",newValue !== true,isNaN(newValue))
            newValue = min
        }

        if (!/^\d+(\.?\,?\d*)?$/.test(newValue) || newValue < 0) {
            newValue = '0';
        }
        if (newValue < min) {
            newValue = min
        }
        if (newValue > max) {
            newValue = max
        }
        let currend = normalizeVolume(+newValue, this.getStepOutput())

        if ((newValue.toString().includes('.') && newValue.toString().endsWith('0')) || newValue.toString().endsWith('.') || newValue === '0') {
            if (PrecisionCount(newValue) > this.state.currentCoin.baseCoinScale) {
                newValue = currend
            }
            this.setState({inputTurnoverBuy: newValue})
            this.setState({inputBuy: Number(tf(1 / this.state.currentRate * newValue, this.coinStore.coin.coinScale))})
        } else {
            this.setState({inputTurnoverBuy: currend})
            this.setState({inputBuy: Number(tf(1 / this.state.currentRate * currend, this.coinStore.coin.coinScale))})
        }

        console.log("validateOutput", this.state.currentRate, this.state.inputBuy, this.state.inputTurnoverBuy)
    }

    validateInput = (newValue) => {
        let min = 0;
        let max = 100000000

        if (newValue !== true && isNaN(newValue)) {
            //console.log("55",newValue !== true,isNaN(newValue))
            newValue = min
        }

        if (!/^\d+(\.?\,?\d*)?$/.test(newValue) || newValue < 0) {
            newValue = '0';
        }
        if (newValue < min) {
            newValue = min
        }
        if (newValue > max) {
            newValue = max
        }
        let currend = normalizeVolume(+newValue, this.getStep())

        if ((newValue.toString().includes('.') && newValue.toString().endsWith('0')) || newValue.toString().endsWith('.') || newValue === '0') {
            if (PrecisionCount(newValue) > this.state.currentCoin.coinScale) {
                newValue = currend
            }
            this.setState({inputBuy: newValue})
            this.setState({inputTurnoverBuy: Number(tf(this.state.currentRate * newValue, this.coinStore.coin.baseCoinScale))})
        } else {
            this.setState({inputBuy: currend})
            this.setState({inputTurnoverBuy: Number(tf(this.state.currentRate * currend, this.coinStore.coin.baseCoinScale))})
        }

        console.log(this.state.currentRate, this.state.inputBuy, this.state.inputTurnoverBuy)
    }

    setCurrentRate = (newValue) => {
        let currend = normalizeVolume(+newValue, this.getStepOutput())
        if ((newValue.includes('.') && newValue.endsWith('0')) || newValue.endsWith('.') || newValue === '0') {
            if (PrecisionCount(newValue) > this.state.currentCoin.baseCoinScale) {
                newValue = currend
            }
            this.setState({currentRate: newValue})
            this.setState({inputTurnoverBuy: Number(tf(this.state.inputBuy * newValue, this.coinStore.coin.baseCoinScale))})
        } else {
            this.setState({currentRate: currend})
            this.setState({inputTurnoverBuy: Number(tf(this.state.inputBuy * currend, this.coinStore.coin.baseCoinScale))})
        }
    }

    handleKeyPress = (event) => {
        // Разрешаем: backspace, delete, tab и escape
        let eventNative = event.nativeEvent

        if (eventNative.keyCode == 44 || eventNative.keyCode == 46 || eventNative.keyCode == 8 || eventNative.keyCode == 9 || eventNative.keyCode == 27 ||
            // Разрешаем: Ctrl+A
            (eventNative.keyCode == 65 && eventNative.ctrlKey === true) ||
            // Разрешаем: home, end, влево, вправо
            (eventNative.keyCode >= 35 && eventNative.keyCode <= 39)) {
            // Ничего не делаем
            return;
        } else {
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if ((eventNative.keyCode < 48 || eventNative.keyCode > 57)) {
                event.preventDefault();
            }
        }
    }

    getStep = () => {
        if (this.state.currentCoin) {
            return 1 / Math.pow(10, this.state.currentCoin.coinScale)
        } else {
            return 0.1
        }
    }

    getStepOutput = () => {
        if (this.state.currentCoin) {
            return 1 / Math.pow(10, this.state.currentCoin.baseCoinScale)
        } else {
            return 0.1
        }
    }

    render() {
        const {pair, priceType, coins, currentCoin, currentRate, inputBuy, inputTurnoverBuy} = this.state;

        const fff = ['BTCRUB','BTCUSDT', 'LTCUSDT' ,'USDTRUB'];

        // @ts-ignore
        // @ts-ignore
        return (
            <main className="main">
                <section className="exchange-section">
                    <div className="container">
                        <div className="tabs js-tabs">
                            <ul className="tabs__list js-tab-list">
                                {fff?.map((one) => (
                                    <li key={one} className={classNames('tabs__item', {active: pair === one})}
                                        onClick={async () => {
                                            await this.setState({pair: one});
                                            await this.onGetCoin()
                                        }}>{one}</li>

                                ))}

                            </ul>
                            <div className="tabs__content js-tab-content active">
                                <div className="deal js-deal-tabs">
                                    <ul className="deal__list js-deal-list">
                                    <li className={classNames('deal__item', {active: priceType === 'market'})}
                                            onClick={() => this.setState({priceType: 'market'})}>По рынку
                                        </li>
                                        <li className={classNames('deal__item', {active: priceType === 'limit'})}
                                            onClick={() => this.setState({priceType: 'limit'})}>Лимит
                                        </li>
                                        
                                    </ul>
                                    <div className={classNames('deal__content', {active: currentCoin != null})}>
                                        <div className="main-content">
                                            {currentCoin?.symbol==pair && <FormBuy currentCoin={currentCoin} type={priceType}/>}
                                            {currentCoin?.symbol==pair && <FormSell currentCoin={currentCoin} type={priceType}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {currentCoin?.symbol==pair && <OrderBook symbol={currentCoin?.symbol} baseSymbol={currentCoin?.baseSymbol}
                                                   coinSymbol={currentCoin?.coinSymbol}/>}
                    </div>
                </section>
                <section className="last-section">
                    {currentCoin?.symbol==pair  && <HistoryOrders symbol={currentCoin?.symbol} baseSymbol={currentCoin?.baseSymbol}
                                                   coinSymbol={currentCoin?.coinSymbol}/>}
                </section>
            </main>
        )
    }
}
