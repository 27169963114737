import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';

import { rootStore } from '../Application';

const DepositMethod = () => {
    const textOptionsDeposit = ['Пополнение с MaxCode', 'Пополнение с MaxCode', 'Пополнение с ExmoCode'];
    const [showOptionsDeposit, setShowOptionsDeposit] = useState(false);
    const [selectTitleDeposit, setSelectTitleDeposit] = useState(textOptionsDeposit[0]);

    const handleTitleSelect = () => {
        if (showOptionsDeposit) {
            setShowOptionsDeposit(false);
          } else {
            setShowOptionsDeposit(true);
          }
    };

    
    const handleLabelSelect = (evt) => {
        setSelectTitleDeposit(evt.target.textContent);
        setShowOptionsDeposit(() => (false));
    }

    const handleChange = (event) => {
        rootStore.coinBaze.setSelectDeposite(event.target.value);
    };

    return (
        (<div className="main-form__field">
            <label className="main-form__label" htmlFor="#">Способ депозита</label>
            <div className={`deposit-select ${showOptionsDeposit ? `deposit-select--active` : ``}`}>
                <div onClick={handleTitleSelect}  className="deposit-select__title">{selectTitleDeposit}</div>
                <div className="deposit-select__list-options list-deposit">
                {['MaxCode','MaxCode', 'ExmoCode'].map((element, index) => {
                    return (
                        (<React.Fragment key={`${element}-${index}`}>
                            <input onChange={handleChange} value={element} id={`depositSelect${index}`} className="list-deposit__element-input" type="radio" name="singleDepositSelect" />
                            <label onClick={handleLabelSelect} htmlFor={`depositSelect${index}`} className="list-deposit__element-label">{textOptionsDeposit[index]}</label>
                        </React.Fragment>)
                    );
                })}
                </div>
            </div>
        </div>)
    );
};

export default observer(DepositMethod);