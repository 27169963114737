import { RootStore } from "./";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import store from 'store';
import { injectable } from "inversify";
import qs from "qs";
import { ProfileType } from "../utils/graphql";
import { WS_HOST, WS_PROTO } from "../utils/const";

@injectable()
export class AuthStore {
    @observable initialized = false;
    @observable token?: string;
    @observable profile?: ProfileType;
    @observable websocket?: WebSocket;
    @observable unreadMessages: number = 0;

    get api() {
        return this.rootStore.api;
    }

    public constructor(protected rootStore: RootStore) {
        makeObservable(this);
        this.initialize();
    }

    @action async initialize() {
        this.token = store.get('token');
        await this.loadUserInfo();
        this.initialized = true;
        setInterval(() => this.loadUserInfo(), 10000);

        const q = qs.parse(this.rootStore.routerStore.location.search, { ignoreQueryPrefix: true });
        if (q.r) {
            store.set('referrer', q.r);
        }
    }

    loadUserInfo = async () => {
        if (this.token) {
            try {
                const profile = await this.api.getProfile();
                runInAction(() => {
                    this.profile = profile;
                    if (!this.websocket) {
                        this.websocket = new WebSocket(`${WS_PROTO}://${WS_HOST}/ws/chat/?token=${this.token}`);
                        this.websocket.addEventListener('message', this.onWebsocketMessage);
                    } else {
                        this.websocket.send(JSON.stringify({ action: 'ping' }))
                    }
                });
            } catch (e) {
                if (e?.response?.status === 401) {
                    this.logout();
                }
            }
        }
    }

    onWebsocketMessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data);

        if (data.action === 'unread_ticker') {
            runInAction(() => this.unreadMessages = data.unread);
        }
    }

    async tokenAuth(email: string, password: string, otpCode: string): Promise<void> {
        this.token = await this.api.tokenAuth(email, password, otpCode);
        store.set('token', this.token);
        await this.loadUserInfo();
        store.remove('referrer');
    }

    async register(name: string, email: string, password: string): Promise<void> {
        await this.api.register(name, email, password, store.get('referrer'));
        store.remove('referrer');
    }

    async passwordReset(email: string): Promise<void> {
        await this.api.passwordReset(email);
    }

    async updateName(name: string): Promise<void> {
        await this.api.updateName(name);
        await this.loadUserInfo();
    }

    async updatePhone(phone: string): Promise<void> {
        await this.api.updatePhone(phone);
        await this.loadUserInfo();
    }

    async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
        await this.api.updatePassword(oldPassword, newPassword);
    }

    async toggle2fa(code: string): Promise<void> {
        await this.api.toggle2fa(code);
        await this.loadUserInfo();
    }

    async updateTg(tgUsername: string, inviterTgUsername: string): Promise<void> {
        await this.api.updateTg(tgUsername, inviterTgUsername);
        await this.loadUserInfo();
    }

    async has2FA(email: string): Promise<boolean> {
        return await this.api.has2FA(email);
    }

    @computed get referralLink() {
        const a = document.createElement('a');
        a.setAttribute('href', `/?r=${this.profile?.referralCode}`);
        return a.href;
    }

    @action logout = () => {
        this.token = this.profile = undefined;
        store.remove('token');
        this.rootStore.routerStore.history.push('/');
        store.remove('referrer');
        this.websocket.close();
        this.websocket = undefined;
        this.unreadMessages = 0;
    }
}
