import React from 'react';
import { inject, observer } from "mobx-react";
import { Modals, ModalStore } from "../stores/ModalStore";
import { resolve } from "inversify-react";
import { ScrollLock } from "./ScrollLock";
import { reaction } from "mobx";
import ClickAwayListener from "react-click-away-listener";

interface IModalProps {
    modalClassName?: string;
    children: React.ReactNode | React.ReactNodeArray;
    modalKey: Modals;
    onShow?: () => any;
}

interface IModalState {
}

@observer
export class Modal extends React.Component<IModalProps, IModalState> {
    private fadeRef: HTMLDivElement;

    @resolve(ModalStore)
    declare private readonly modalStore: ModalStore;

    hide = () => {
        this.modalStore.hideModals();
    }

    componentDidMount() {
        reaction(() => this.modalStore.activeModal, val => val === this.props.modalKey && this.props.onShow && this.props.onShow());
    }

    render() {
        let { modalClassName, children, modalKey } = this.props;
        const show = this.modalStore.activeModal === modalKey;

        return (
            <>
                {show && <ScrollLock />}
                <div className={`mask ${show && 'active'}`}>
                    <ClickAwayListener onClickAway={() => show && this.hide()} >
                        <div className={`modal ${modalClassName}`}>
                            <div className="modal__wrap">
                                {children}
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
            </>
        )
    }
}
