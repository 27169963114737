import React from 'react';
import { Modal } from "../components/Modal";
import { Modals, ModalStore } from "../stores/ModalStore";
import { resolve } from "inversify-react";
import { pd, processRequestError } from "../utils/utilities";
import { observer } from "mobx-react";
import { Button } from "../components/Button";
import { toast } from "react-toastify";
import { AuthStore } from "../stores";

interface IRecoveryModalProps {
}

interface IRecoveryModalState {
    email: string;
    loading: boolean;
}

@observer
export class RecoveryModal extends React.Component<IRecoveryModalProps, IRecoveryModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IRecoveryModalState = {
        email: '',
        loading: false,
    }

    onSubmit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.passwordReset(this.state.email);
            toast.success('Временный пароль был выслан на указанный адрес электронной почты');
            this.modalStore.showModal(Modals.SignIn);
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { email, loading } = this.state;

        return (
            <Modal modalKey={Modals.Recover}>
                <form className="main-form" onSubmit={this.onSubmit}>
                    <div className="main-form__wrap">
                        <h3 className="main-form__title">Восстановить пароль</h3>
                        <p className="main-form__info">
                            Мы отправим Вам временный пароль на почту для восстановления доступа к аккаунту.
                        </p>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                        </div>
                        <Button kind='primary' type='submit' loading={loading} disabled={!email}>Отправить</Button>
                    </div>
                </form>
            </Modal>
        )
    }
}