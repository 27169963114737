import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';

import {BalanceExchangeType, CoinType} from "../utils/graphql";
import { rootStore } from '../Application';
import {tf} from "../utils/utilities";

import { TCurrency } from '../types/currency';

interface IInfoCurrencyWallet {
    currency: TCurrency;
    mantissa: number;
};

const getComponentImageSymbol = (symbol) => {
    switch(symbol) {
        case 'BTC':
            return (
                <img src={require(`../images/btc.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'RUB':
            return (
                <img src={require(`../images/rub.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'USDT':
            return (
                <img src={require(`../images/usdt.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'LTC':
            return (
                <img src={require(`../images/ltc.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );  
        case 'UZS':
            return (
                <img src={require(`../images/uzs.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'AED':
            return (
                <img src={require(`../images/aed.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'KZT':
            return (
                <img src={require(`../images/kzt.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );               
    }
}



const InfoCurrencyWallet: FC<IInfoCurrencyWallet> = ({currency, mantissa}) => {

    const onGetBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === currency)
            if (findOne) {
                return findOne.balance
            }
        }
        return 0
    }
    const onGetFreezBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === currency)
            if (findOne) {
                return findOne.freezeBalance
            }
        }
        return 0
    }

    return (
        <section className="info-currency-wallet">
            {/* <span className="">{tf(onGetBalance(),mantissa)} {currency}</span>
            <span className="">{tf(onGetFreezBalance(),mantissa)} {currency}</span>
            {getComponentImageSymbol(currency)} */}
        </section>
    );
};

export default observer(InfoCurrencyWallet);