import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
};

export enum AppOtcAdActionChoices {
  Buy = 'BUY',
  Sell = 'SELL'
}

export enum AppOtcAdCurrencyChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Fnk = 'FNK',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Partner = 'PARTNER',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum AppOtcAdStatusChoices {
  Active = 'ACTIVE',
  Completed = 'COMPLETED'
}

export enum AppOtcDealStateChoices {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Created = 'CREATED',
  Offered = 'OFFERED',
  Paid = 'PAID'
}

export enum AppOperationLogCurrencyChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Fnk = 'FNK',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Partner = 'PARTNER',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum AppOperationLogTypeChoices {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW'
}

export type BalanceExchangeType = {
  balance?: Maybe<Scalars['Decimal']>;
  freezeBalance?: Maybe<Scalars['Decimal']>;
  symbol: ExchangeExchangeBalanceSymbolChoices;
};

export type BookDataType = {
  buy?: Maybe<Array<Maybe<ExchangeOrdersType>>>;
  sell?: Maybe<Array<Maybe<ExchangeOrdersType>>>;
};

export type CheckExmoCodeMutation = {
  error?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CoinType = {
  baseCoinScale: Scalars['Int'];
  baseSymbol: ExchangeExchangeCoinBasesymbolChoices;
  coinScale: Scalars['Int'];
  coinSymbol: ExchangeExchangeCoinCoinsymbolChoices;
  enable: Scalars['Boolean'];
  fee: Scalars['Decimal'];
  rubRate: Scalars['Decimal'];
  symbol: Scalars['String'];
  usdRate: Scalars['Decimal'];
};

export type CreateExmoCodeMutation = {
  error?: Maybe<Scalars['String']>;
  exmoCode?: Maybe<ExmoCodeType>;
};

export type CreateOrderMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};



export type DepositAedMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type DepositKztMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type DepositRubMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type DepositUzsMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export enum ExchangeExchangeBalanceSymbolChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum ExchangeExchangeCoinBasesymbolChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum ExchangeExchangeCoinCoinsymbolChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum ExchangeExchangeOrderBasesymbolChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum ExchangeExchangeOrderCoinsymbolChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export enum ExchangeExchangeOrderDirectionChoices {
  Buy = 'BUY',
  Sell = 'SELL'
}

export enum ExchangeExchangeOrderStatusChoices {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Overtimed = 'OVERTIMED',
  Trading = 'TRADING'
}

export enum ExchangeExchangeOrderTypeChoices {
  Limit = 'LIMIT',
  Market = 'MARKET'
}

export enum ExchangeExmoCodeCurrencyChoices {
  Aed = 'AED',
  Btc = 'BTC',
  Kzt = 'KZT',
  Ltc = 'LTC',
  Rub = 'RUB',
  Usdt = 'USDT',
  Uzs = 'UZS',
  Xmr = 'XMR'
}

export type ExchangeOrderType = {
  amount: Scalars['Decimal'];
  baseSymbol: ExchangeExchangeOrderBasesymbolChoices;
  coinSymbol: ExchangeExchangeOrderCoinsymbolChoices;
  direction: ExchangeExchangeOrderDirectionChoices;
  orderId: Scalars['ID'];
  price: Scalars['Decimal'];
  status: ExchangeExchangeOrderStatusChoices;
  symbol: Scalars['String'];
  time?: Maybe<Scalars['DateTime']>;
  timeCanceled?: Maybe<Scalars['DateTime']>;
  timeComplited?: Maybe<Scalars['DateTime']>;
  tradedAmount: Scalars['Decimal'];
  tradedTurnover: Scalars['Decimal'];
  turnover: Scalars['Decimal'];
  type: ExchangeExchangeOrderTypeChoices;
};

export type ExchangeOrdersType = {
  amounts?: Maybe<Scalars['Decimal']>;
  price?: Maybe<Scalars['Decimal']>;
  symbol?: Maybe<Scalars['String']>;
  turnovers?: Maybe<Scalars['Decimal']>;
  type?: Maybe<Scalars['String']>;
};

export type ExmoCodeType = {
  activatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: ExchangeExmoCodeCurrencyChoices;
  id: Scalars['ID'];
  status: Scalars['String'];
  user?: Maybe<ProfileType>;
};

export type IndexPageInfoType = {
  bottomImage1?: Maybe<Scalars['String']>;
  bottomImage2?: Maybe<Scalars['String']>;
  bottomImage3?: Maybe<Scalars['String']>;
  bottomText1?: Maybe<Scalars['String']>;
  bottomText2?: Maybe<Scalars['String']>;
  bottomText3?: Maybe<Scalars['String']>;
  topImage?: Maybe<Scalars['String']>;
  topText?: Maybe<Scalars['String']>;
};

export type Mutation = {
  cancelOrder?: Maybe<OrderCancelMutation>;
  checkExmoCode?: Maybe<CheckExmoCodeMutation>;
  createExmoCode?: Maybe<CreateExmoCodeMutation>;
  createOrder?: Maybe<CreateOrderMutation>;
  depositAed?: Maybe<DepositAedMutation>;
  depositKzt?: Maybe<DepositKztMutation>;
  depositRub?: Maybe<DepositRubMutation>;
  depositUzs?: Maybe<DepositUzsMutation>;
  otcCreateAd?: Maybe<OtcCreateAdMutation>;
  otcDealAccept?: Maybe<OtcDealAcceptMutation>;
  otcDealComplete?: Maybe<OtcDealCompleteMutation>;
  otcDealDecline?: Maybe<OtcDealDeclineMutation>;
  otcDealOffer?: Maybe<OtcDealOfferMutation>;
  otcDealPaid?: Maybe<OtcDealPaidMutation>;
  otcDeleteAd?: Maybe<OtcDeleteAdMutation>;
  otcEditAd?: Maybe<OtcEditAdMutation>;
  otcStartDeal?: Maybe<OtcStartDealMutation>;
  passwordReset?: Maybe<PasswordResetMutation>;
  redeemExmoCode?: Maybe<RedeemExmoCodeMutation>;
  register?: Maybe<RegisterMutation>;
  toggle2fa?: Maybe<Toggle2FaMutation>;
  tokenAuth?: Maybe<TokenAuthMutation>;
  updateName?: Maybe<UpdateNameMutation>;
  updatePassword?: Maybe<UpdatePasswordMutation>;
  updatePhone?: Maybe<UpdatePhoneMutation>;
  updateTg?: Maybe<UpdateTgMutation>;
  withdrawAed?: Maybe<WithdrawAedMutation>;
  withdrawBtc?: Maybe<WithdrawBtcMutation>;
  withdrawFnk?: Maybe<WithdrawFnkMutation>;
  withdrawKzt?: Maybe<WithdrawKztMutation>;
  withdrawLtc?: Maybe<WithdrawLtcMutation>;
  withdrawPartner?: Maybe<WithdrawPartnerMutation>;
  withdrawRub?: Maybe<WithdrawRubMutation>;
  withdrawUsdt?: Maybe<WithdrawUsdtMutation>;
  withdrawUzs?: Maybe<WithdrawUzsMutation>;
  withdrawXmr?: Maybe<WithdrawXmrMutation>;
};


export type Mutation_CancelOrderArgs = {
  pk: Scalars['String'];
};


export type Mutation_CheckExmoCodeArgs = {
  code: Scalars['String'];
};


export type Mutation_CreateExmoCodeArgs = {
  amount: Scalars['Decimal'];
  currency: Scalars['String'];
};


export type Mutation_CreateOrderArgs = {
  amount: Scalars['Decimal'];
  direction: Scalars['String'];
  price: Scalars['Decimal'];
  symbol: Scalars['String'];
  turnover: Scalars['Decimal'];
  type: Scalars['String'];
};


export type Mutation_DepositAedArgs = {
  code?: Maybe<Scalars['String']>;
};


export type Mutation_DepositKztArgs = {
  code?: Maybe<Scalars['String']>;
};


export type Mutation_DepositRubArgs = {
  code?: Maybe<Scalars['String']>;
};


export type Mutation_DepositUzsArgs = {
  code?: Maybe<Scalars['String']>;
};


export type Mutation_OtcCreateAdArgs = {
  action: Scalars['String'];
  currency: Scalars['String'];
  description: Scalars['String'];
  maxSum: Scalars['Decimal'];
  minSum: Scalars['Decimal'];
  paymentMethod: Scalars['String'];
  priceExtraPercent: Scalars['Decimal'];
};


export type Mutation_OtcDealAcceptArgs = {
  pk: Scalars['String'];
};


export type Mutation_OtcDealCompleteArgs = {
  pk: Scalars['String'];
};


export type Mutation_OtcDealDeclineArgs = {
  pk: Scalars['String'];
};


export type Mutation_OtcDealOfferArgs = {
  amount: Scalars['Decimal'];
  pk: Scalars['String'];
};


export type Mutation_OtcDealPaidArgs = {
  pk: Scalars['String'];
};


export type Mutation_OtcDeleteAdArgs = {
  pk: Scalars['String'];
};


export type Mutation_OtcEditAdArgs = {
  action: Scalars['String'];
  currency: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  maxSum: Scalars['Decimal'];
  minSum: Scalars['Decimal'];
  paymentMethod: Scalars['String'];
  priceExtraPercent: Scalars['Decimal'];
};


export type Mutation_OtcStartDealArgs = {
  adId: Scalars['String'];
};


export type Mutation_PasswordResetArgs = {
  email: Scalars['String'];
};


export type Mutation_RedeemExmoCodeArgs = {
  code: Scalars['String'];
};


export type Mutation_RegisterArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  referrer?: Maybe<Scalars['String']>;
};


export type Mutation_Toggle2faArgs = {
  code: Scalars['String'];
};


export type Mutation_TokenAuthArgs = {
  email: Scalars['String'];
  otpCode: Scalars['String'];
  password: Scalars['String'];
};


export type Mutation_UpdateNameArgs = {
  newName: Scalars['String'];
};


export type Mutation_UpdatePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type Mutation_UpdatePhoneArgs = {
  newPhone: Scalars['String'];
};


export type Mutation_UpdateTgArgs = {
  inviterTgUsername?: Maybe<Scalars['String']>;
  tgUsername: Scalars['String'];
};


export type Mutation_WithdrawAedArgs = {
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawBtcArgs = {
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawFnkArgs = {
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawKztArgs = {
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawLtcArgs = {
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawPartnerArgs = {
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawRubArgs = {
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawUsdtArgs = {
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawUzsArgs = {
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};


export type Mutation_WithdrawXmrArgs = {
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  otpCode?: Maybe<Scalars['String']>;
};

export type OtcAdType = {
  action: AppOtcAdActionChoices;
  currency: AppOtcAdCurrencyChoices;
  description: Scalars['String'];
  id: Scalars['ID'];
  maxSum: Scalars['Decimal'];
  minSum: Scalars['Decimal'];
  paymentMethod: Scalars['String'];
  priceExtraPercent: Scalars['Decimal'];
  status: AppOtcAdStatusChoices;
  user?: Maybe<ReferralType>;
};

export type OtcAdsType = {
  buy?: Maybe<Array<OtcAdType>>;
  sell?: Maybe<Array<OtcAdType>>;
};

export type OtcCreateAdMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcDealAcceptMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcDealCompleteMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcDealDeclineMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcDealOfferMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcDealPaidMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcDealType = {
  ad?: Maybe<OtcAdType>;
  amount?: Maybe<Scalars['Decimal']>;
  datetime: Scalars['DateTime'];
  hasUnread?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  state: AppOtcDealStateChoices;
  status?: Maybe<Scalars['Boolean']>;
  user?: Maybe<ReferralType>;
};

export type OtcDeleteAdMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcEditAdMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type OtcStartDealMutation = {
  dealId?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type OperationLogType = {
  currency: AppOperationLogCurrencyChoices;
  datetime: Scalars['DateTime'];
  destination: Scalars['String'];
  id: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
  txid?: Maybe<Scalars['String']>;
  type: AppOperationLogTypeChoices;
  value: Scalars['Decimal'];
};

export type OperationsType = {
  deposits?: Maybe<Array<OperationLogType>>;
  withdraws?: Maybe<Array<OperationLogType>>;
};

export type OrderCancelMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type PageListType = {
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<ExchangeOrderType>>;
  limit?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
};

export type PasswordResetMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type ProfileType = {
  aedBalance: Scalars['Decimal'];
  btcAddress?: Maybe<Scalars['String']>;
  btcBalance: Scalars['Decimal'];
  email: Scalars['String'];
  emailValidated: Scalars['Boolean'];
  ethAddress?: Maybe<Scalars['String']>;
  fnkBalance: Scalars['Decimal'];
  id: Scalars['ID'];
  inviterTgUsername?: Maybe<Scalars['String']>;
  kztBalance: Scalars['Decimal'];
  lockedBtc?: Maybe<Scalars['Decimal']>;
  lockedFnk?: Maybe<Scalars['Decimal']>;
  lockedUsdt?: Maybe<Scalars['Decimal']>;
  ltcAddress?: Maybe<Scalars['String']>;
  ltcBalance?: Maybe<Scalars['Decimal']>;
  name: Scalars['String'];
  otpEnabled: Scalars['Boolean'];
  otpSecret?: Maybe<Scalars['String']>;
  partnerBalance: Scalars['Decimal'];
  phone?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referrals?: Maybe<Array<ReferralType>>;
  referrer?: Maybe<Scalars['String']>;
  rubBalance: Scalars['Decimal'];
  tgUsername?: Maybe<Scalars['String']>;
  tronAddress?: Maybe<Scalars['String']>;
  usdtBalance: Scalars['Decimal'];
  uzsBalance: Scalars['Decimal'];
  xmrAddress?: Maybe<Scalars['String']>;
  xmrBalance?: Maybe<Scalars['Decimal']>;
};

export type Query = {
  ads?: Maybe<OtcAdsType>;
  basesymbol?: Maybe<Array<Maybe<Scalars['String']>>>;
  books?: Maybe<BookDataType>;
  coin?: Maybe<CoinType>;
  coins?: Maybe<Array<Maybe<Scalars['String']>>>;
  deal?: Maybe<OtcDealType>;
  getBalance?: Maybe<Scalars['Decimal']>;
  getBalancesAll?: Maybe<Array<Maybe<BalanceExchangeType>>>;
  getBalancesOne?: Maybe<BalanceExchangeType>;
  getOperations?: Maybe<OperationsType>;
  has2fa?: Maybe<Scalars['Boolean']>;
  indexPage?: Maybe<IndexPageInfoType>;
  ltcDepositAddress?: Maybe<Scalars['String']>;
  myAds?: Maybe<Array<OtcAdType>>;
  myDeals?: Maybe<Array<OtcDealType>>;
  myExmoCodes?: Maybe<Array<Maybe<ExmoCodeType>>>;
  orderCurrent?: Maybe<PageListType>;
  orderHistory?: Maybe<PageListType>;
  profile?: Maybe<ProfileType>;
  xmrDepositAddress?: Maybe<Scalars['String']>;
};


export type Query_BooksArgs = {
  limit?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
};


export type Query_CoinArgs = {
  symbol?: Maybe<Scalars['String']>;
};


export type Query_CoinsArgs = {
  baseSymbol?: Maybe<Scalars['String']>;
};


export type Query_DealArgs = {
  pk?: Maybe<Scalars['String']>;
};


export type Query_GetBalanceArgs = {
  symbol: Scalars['String'];
};


export type Query_GetBalancesOneArgs = {
  symbol: Scalars['String'];
};


export type Query_GetOperationsArgs = {
  currency?: Maybe<Scalars['String']>;
};


export type Query_Has2faArgs = {
  email: Scalars['String'];
};


export type Query_OrderCurrentArgs = {
  limit?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
};


export type Query_OrderHistoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
};

export type RedeemExmoCodeMutation = {
  error?: Maybe<Scalars['String']>;
  exmoCode?: Maybe<ExmoCodeType>;
};

export type ReferralType = {
  dateJoined: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  referralBonuses: Scalars['Decimal'];
};

export type RegisterMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type Toggle2FaMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type TokenAuthMutation = {
  token?: Maybe<Scalars['String']>;
};

export type UpdateNameMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdatePasswordMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdatePhoneMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateTgMutation = {
  ok: Scalars['String'];
};

export type WithdrawAedMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawBtcMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawFnkMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawKztMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawLtcMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawPartnerMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawRubMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawUsdtMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawUzsMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type WithdrawXmrMutation = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type TokenAuthVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  otpCode: Scalars['String'];
}>;


export type TokenAuth = { tokenAuth?: Maybe<Pick<TokenAuthMutation, 'token'>> };

export type RegisterVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  referrer?: Maybe<Scalars['String']>;
}>;


export type Register = { register?: Maybe<Pick<RegisterMutation, 'ok'>> };

export type PasswordResetVariables = Exact<{
  email: Scalars['String'];
}>;


export type PasswordReset = { passwordReset?: Maybe<Pick<PasswordResetMutation, 'ok'>> };

export type UpdateNameVariables = Exact<{
  name: Scalars['String'];
}>;


export type UpdateName = { updateName?: Maybe<Pick<UpdateNameMutation, 'ok'>> };

export type UpdatePasswordVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdatePassword = { updatePassword?: Maybe<Pick<UpdatePasswordMutation, 'ok'>> };

export type UpdatePhoneVariables = Exact<{
  phone: Scalars['String'];
}>;


export type UpdatePhone = { updatePhone?: Maybe<Pick<UpdatePhoneMutation, 'ok'>> };

export type Toggle2faVariables = Exact<{
  code: Scalars['String'];
}>;


export type Toggle2fa = { toggle2fa?: Maybe<Pick<Toggle2FaMutation, 'ok'>> };

export type UpdateTgVariables = Exact<{
  tgUsername: Scalars['String'];
  inviterTgUsername?: Maybe<Scalars['String']>;
}>;


export type UpdateTg = { updateTg?: Maybe<Pick<UpdateTgMutation, 'ok'>> };

export type DepositRubVariables = Exact<{
  code: Scalars['String'];
}>;


export type DepositRub = { depositRub?: Maybe<Pick<DepositRubMutation, 'ok'>> };

export type RedeemExmoCodeVariables = Exact<{
  code: Scalars['String'];
}>;


export type RedeemExmoCode = { redeemExmoCode?: Maybe<(
    Pick<RedeemExmoCodeMutation, 'error'>
    & { exmoCode?: Maybe<Pick<ExmoCodeType, 'code' | 'amount' | 'currency' | 'status' | 'activatedAt'>> }
  )> };

export type CheckExmoCodeVariables = Exact<{
  code: Scalars['String'];
}>;


export type CheckExmoCode = { checkExmoCode?: Maybe<Pick<CheckExmoCodeMutation, 'status' | 'error'>> };

export type CreateExmoCodeVariables = Exact<{
  currency: Scalars['String'];
  amount: Scalars['Decimal'];
}>;


export type CreateExmoCode = { createExmoCode?: Maybe<(
    Pick<CreateExmoCodeMutation, 'error'>
    & { exmoCode?: Maybe<Pick<ExmoCodeType, 'code' | 'amount' | 'currency' | 'status' | 'createdAt'>> }
  )> };

export type DepositKztVariables = Exact<{
  code: Scalars['String'];
}>;


export type DepositKzt = { depositKzt?: Maybe<Pick<DepositKztMutation, 'ok'>> };

export type DepositUzsVariables = Exact<{
  code: Scalars['String'];
}>;


export type DepositUzs = { depositUzs?: Maybe<Pick<DepositUzsMutation, 'ok'>> };

export type DepositAedVariables = Exact<{
  code: Scalars['String'];
}>;


export type DepositAed = { depositAed?: Maybe<Pick<DepositAedMutation, 'ok'>> };

export type WithdrawKztVariables = Exact<{
  amount: Scalars['Decimal'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawKzt = { withdrawKzt?: Maybe<Pick<WithdrawKztMutation, 'ok'>> };

export type WithdrawAedVariables = Exact<{
  amount: Scalars['Decimal'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawAed = { withdrawAed?: Maybe<Pick<WithdrawAedMutation, 'ok'>> };

export type WithdrawUzsVariables = Exact<{
  amount: Scalars['Decimal'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawUzs = { withdrawUzs?: Maybe<Pick<WithdrawUzsMutation, 'ok'>> };

export type WithdrawRubVariables = Exact<{
  amount: Scalars['Decimal'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawRub = { withdrawRub?: Maybe<Pick<WithdrawRubMutation, 'ok'>> };

export type WithdrawPartnerVariables = Exact<{
  amount: Scalars['Decimal'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawPartner = { withdrawPartner?: Maybe<Pick<WithdrawPartnerMutation, 'ok'>> };

export type WithdrawBtcVariables = Exact<{
  amount: Scalars['Decimal'];
  address: Scalars['String'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawBtc = { withdrawBtc?: Maybe<Pick<WithdrawBtcMutation, 'ok'>> };

export type WithdrawXmrVariables = Exact<{
  amount: Scalars['Decimal'];
  address: Scalars['String'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawXmr = { withdrawXmr?: Maybe<Pick<WithdrawXmrMutation, 'ok'>> };

export type WithdrawLtcVariables = Exact<{
  amount: Scalars['Decimal'];
  address: Scalars['String'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawLtc = { withdrawLtc?: Maybe<Pick<WithdrawLtcMutation, 'ok'>> };

export type WithdrawUsdtVariables = Exact<{
  amount: Scalars['Decimal'];
  address: Scalars['String'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawUsdt = { withdrawUsdt?: Maybe<Pick<WithdrawUsdtMutation, 'ok'>> };

export type WithdrawFnkVariables = Exact<{
  amount: Scalars['Decimal'];
  address: Scalars['String'];
  otpCode?: Maybe<Scalars['String']>;
}>;


export type WithdrawFnk = { withdrawFnk?: Maybe<Pick<WithdrawFnkMutation, 'ok'>> };

export type OtcCreateAdVariables = Exact<{
  action: Scalars['String'];
  currency: Scalars['String'];
  minSum: Scalars['Decimal'];
  maxSum: Scalars['Decimal'];
  paymentMethod: Scalars['String'];
  description: Scalars['String'];
  priceExtraPercent: Scalars['Decimal'];
}>;


export type OtcCreateAd = { otcCreateAd?: Maybe<Pick<OtcCreateAdMutation, 'ok'>> };

export type OtcEditAdVariables = Exact<{
  id: Scalars['String'];
  currency: Scalars['String'];
  action: Scalars['String'];
  minSum: Scalars['Decimal'];
  maxSum: Scalars['Decimal'];
  paymentMethod: Scalars['String'];
  description: Scalars['String'];
  priceExtraPercent: Scalars['Decimal'];
}>;


export type OtcEditAd = { otcEditAd?: Maybe<Pick<OtcEditAdMutation, 'ok'>> };

export type OtcDeleteAdVariables = Exact<{
  pk: Scalars['String'];
}>;


export type OtcDeleteAd = { otcDeleteAd?: Maybe<Pick<OtcDeleteAdMutation, 'ok'>> };

export type OtcStartDealVariables = Exact<{
  adId: Scalars['String'];
}>;


export type OtcStartDeal = { otcStartDeal?: Maybe<Pick<OtcStartDealMutation, 'ok' | 'dealId'>> };

export type OtcDealOfferVariables = Exact<{
  pk: Scalars['String'];
  amount: Scalars['Decimal'];
}>;


export type OtcDealOffer = { otcDealOffer?: Maybe<Pick<OtcDealOfferMutation, 'ok'>> };

export type OtcDealAcceptVariables = Exact<{
  pk: Scalars['String'];
}>;


export type OtcDealAccept = { otcDealAccept?: Maybe<Pick<OtcDealAcceptMutation, 'ok'>> };

export type OtcDealDeclineVariables = Exact<{
  pk: Scalars['String'];
}>;


export type OtcDealDecline = { otcDealDecline?: Maybe<Pick<OtcDealDeclineMutation, 'ok'>> };

export type OtcDealPaidVariables = Exact<{
  pk: Scalars['String'];
}>;


export type OtcDealPaid = { otcDealPaid?: Maybe<Pick<OtcDealPaidMutation, 'ok'>> };

export type OtcDealCompleteVariables = Exact<{
  pk: Scalars['String'];
}>;


export type OtcDealComplete = { otcDealComplete?: Maybe<Pick<OtcDealCompleteMutation, 'ok'>> };

export type CreateOrderVariables = Exact<{
  symbol: Scalars['String'];
  direction: Scalars['String'];
  type: Scalars['String'];
  amount: Scalars['Decimal'];
  price: Scalars['Decimal'];
  turnover: Scalars['Decimal'];
}>;


export type CreateOrder = { createOrder?: Maybe<Pick<CreateOrderMutation, 'ok'>> };

export type OnCancelOrderVariables = Exact<{
  pk: Scalars['String'];
}>;


export type OnCancelOrder = { cancelOrder?: Maybe<Pick<OrderCancelMutation, 'ok'>> };

export type Referral = Pick<ReferralType, 'id' | 'referralBonuses' | 'dateJoined' | 'name'>;

export type GetProfileVariables = Exact<{ [key: string]: never; }>;


export type GetProfile = { profile?: Maybe<(
    Pick<ProfileType, 'id' | 'name' | 'email' | 'phone' | 'otpEnabled' | 'otpSecret' | 'referrer' | 'emailValidated' | 'referralCode' | 'tgUsername' | 'inviterTgUsername' | 'rubBalance' | 'kztBalance' | 'uzsBalance' | 'aedBalance' | 'btcBalance' | 'xmrBalance' | 'ltcBalance' | 'usdtBalance' | 'fnkBalance' | 'partnerBalance' | 'lockedBtc' | 'lockedUsdt' | 'lockedFnk' | 'btcAddress' | 'ethAddress' | 'tronAddress' | 'xmrAddress' | 'ltcAddress'>
    & { referrals?: Maybe<Array<Referral>> }
  )> };

export type Has2FaVariables = Exact<{
  email: Scalars['String'];
}>;


export type Has2Fa = Pick<Query, 'has2fa'>;

export type GetIndexInfoVariables = Exact<{ [key: string]: never; }>;


export type GetIndexInfo = { indexPage?: Maybe<Pick<IndexPageInfoType, 'topText' | 'topImage' | 'bottomText1' | 'bottomImage1' | 'bottomText2' | 'bottomImage2' | 'bottomText3' | 'bottomImage3'>> };

export type Operation = Pick<OperationLogType, 'id' | 'currency' | 'type' | 'value' | 'datetime' | 'status' | 'destination' | 'txid'>;

export type GetOperationsVariables = Exact<{
  currency: Scalars['String'];
}>;


export type GetOperations = { getOperations?: Maybe<{ deposits?: Maybe<Array<Operation>>, withdraws?: Maybe<Array<Operation>> }> };

export type OtcAd = (
  Pick<OtcAdType, 'id' | 'action' | 'minSum' | 'maxSum' | 'paymentMethod' | 'description' | 'priceExtraPercent' | 'currency' | 'status'>
  & { user?: Maybe<Referral> }
);

export type GetMyOtcAdsVariables = Exact<{ [key: string]: never; }>;


export type GetMyOtcAds = { myAds?: Maybe<Array<OtcAd>> };

export type GetOtcAdsVariables = Exact<{ [key: string]: never; }>;


export type GetOtcAds = { ads?: Maybe<{ buy?: Maybe<Array<OtcAd>>, sell?: Maybe<Array<OtcAd>> }> };

export type OtcDeal = (
  Pick<OtcDealType, 'id' | 'datetime' | 'status' | 'amount' | 'state' | 'hasUnread'>
  & { user?: Maybe<Referral>, ad?: Maybe<OtcAd> }
);

export type GetMyOtcDealsVariables = Exact<{ [key: string]: never; }>;


export type GetMyOtcDeals = { myDeals?: Maybe<Array<OtcDeal>> };

export type GetDealVariables = Exact<{
  pk: Scalars['String'];
}>;


export type GetDeal = { deal?: Maybe<OtcDeal> };

export type GetBaseSymbolVariables = Exact<{ [key: string]: never; }>;


export type GetBaseSymbol = Pick<Query, 'basesymbol'>;

export type GetUserBalanceVariables = Exact<{
  symbol: Scalars['String'];
}>;


export type GetUserBalance = Pick<Query, 'getBalance'>;

export type GetCoinsVariables = Exact<{
  baseSymbol: Scalars['String'];
}>;


export type GetCoins = Pick<Query, 'coins'>;

export type GetBooksVariables = Exact<{
  symbol: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type GetBooks = { books?: Maybe<{ buy?: Maybe<Array<Maybe<Pick<ExchangeOrdersType, 'type' | 'symbol' | 'price' | 'amounts' | 'turnovers'>>>>, sell?: Maybe<Array<Maybe<Pick<ExchangeOrdersType, 'type' | 'symbol' | 'price' | 'amounts' | 'turnovers'>>>> }> };

export type GetOrderCurrentVariables = Exact<{
  symbol: Scalars['String'];
  limit: Scalars['Int'];
  pageNo: Scalars['Int'];
}>;


export type GetOrderCurrent = { orderCurrent?: Maybe<(
    Pick<PageListType, 'count' | 'limit' | 'pageNo'>
    & { items?: Maybe<Array<Pick<ExchangeOrderType, 'orderId' | 'price' | 'type' | 'direction' | 'status' | 'amount' | 'turnover' | 'symbol' | 'time' | 'coinSymbol' | 'baseSymbol'>>> }
  )> };

export type GetCoinVariables = Exact<{
  symbol: Scalars['String'];
}>;


export type GetCoin = { coin?: Maybe<Pick<CoinType, 'baseCoinScale' | 'baseSymbol' | 'coinScale' | 'coinSymbol' | 'rubRate' | 'usdRate' | 'enable' | 'fee' | 'symbol'>> };

export type GetOrderHistoryVariables = Exact<{
  symbol: Scalars['String'];
  limit: Scalars['Int'];
  pageNo: Scalars['Int'];
}>;


export type GetOrderHistory = { orderHistory?: Maybe<(
    Pick<PageListType, 'count' | 'limit' | 'pageNo'>
    & { items?: Maybe<Array<Pick<ExchangeOrderType, 'orderId' | 'price' | 'type' | 'direction' | 'status' | 'amount' | 'turnover' | 'tradedAmount' | 'tradedTurnover' | 'symbol' | 'time' | 'coinSymbol' | 'baseSymbol' | 'timeComplited' | 'timeCanceled'>>> }
  )> };

export type GetBalancesAllVariables = Exact<{ [key: string]: never; }>;


export type GetBalancesAll = { getBalancesAll?: Maybe<Array<Maybe<Pick<BalanceExchangeType, 'balance' | 'freezeBalance' | 'symbol'>>>> };

export type GetBalancesOneVariables = Exact<{
  symbol: Scalars['String'];
}>;


export type GetBalancesOne = { getBalancesOne?: Maybe<Pick<BalanceExchangeType, 'balance' | 'freezeBalance' | 'symbol'>> };

export type GetMyExmoCodesVariables = Exact<{ [key: string]: never; }>;


export type GetMyExmoCodes = { myExmoCodes?: Maybe<Array<Maybe<Pick<ExmoCodeType, 'code' | 'amount' | 'currency' | 'status' | 'createdAt' | 'activatedAt'>>>> };

export const Operation = gql`
    fragment operation on OperationLogType {
  id
  currency
  type
  value
  datetime
  status
  destination
  txid
}
    `;
export const Referral = gql`
    fragment referral on ReferralType {
  id
  referralBonuses
  dateJoined
  name
}
    `;
export const OtcAd = gql`
    fragment otcAd on OTCAdType {
  id
  user {
    ...referral
  }
  action
  minSum
  maxSum
  paymentMethod
  description
  priceExtraPercent
  currency
  status
}
    ${Referral}`;
export const OtcDeal = gql`
    fragment otcDeal on OTCDealType {
  id
  user {
    ...referral
  }
  ad {
    ...otcAd
  }
  datetime
  status
  amount
  state
  hasUnread
}
    ${Referral}
${OtcAd}`;
export const TokenAuthDocument = gql`
    mutation tokenAuth($email: String!, $password: String!, $otpCode: String!) {
  tokenAuth(email: $email, password: $password, otpCode: $otpCode) {
    token
  }
}
    `;
export const RegisterDocument = gql`
    mutation register($name: String!, $email: String!, $password: String!, $referrer: String) {
  register(name: $name, email: $email, password: $password, referrer: $referrer) {
    ok
  }
}
    `;
export const PasswordResetDocument = gql`
    mutation passwordReset($email: String!) {
  passwordReset(email: $email) {
    ok
  }
}
    `;
export const UpdateNameDocument = gql`
    mutation updateName($name: String!) {
  updateName(newName: $name) {
    ok
  }
}
    `;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($oldPassword: String!, $newPassword: String!) {
  updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    ok
  }
}
    `;
export const UpdatePhoneDocument = gql`
    mutation updatePhone($phone: String!) {
  updatePhone(newPhone: $phone) {
    ok
  }
}
    `;
export const Toggle2faDocument = gql`
    mutation toggle2fa($code: String!) {
  toggle2fa(code: $code) {
    ok
  }
}
    `;
export const UpdateTgDocument = gql`
    mutation updateTg($tgUsername: String!, $inviterTgUsername: String) {
  updateTg(tgUsername: $tgUsername, inviterTgUsername: $inviterTgUsername) {
    ok
  }
}
    `;
export const DepositRubDocument = gql`
    mutation depositRub($code: String!) {
  depositRub(code: $code) {
    ok
  }
}
    `;
export const RedeemExmoCodeDocument = gql`
    mutation redeemExmoCode($code: String!) {
  redeemExmoCode(code: $code) {
    exmoCode {
      code
      amount
      currency
      status
      activatedAt
    }
    error
  }
}
    `;
export const CheckExmoCodeDocument = gql`
    mutation checkExmoCode($code: String!) {
  checkExmoCode(code: $code) {
    status
    error
  }
}
    `;
export const CreateExmoCodeDocument = gql`
    mutation createExmoCode($currency: String!, $amount: Decimal!) {
  createExmoCode(currency: $currency, amount: $amount) {
    exmoCode {
      code
      amount
      currency
      status
      createdAt
    }
    error
  }
}
    `;
export const DepositKztDocument = gql`
    mutation depositKzt($code: String!) {
  depositKzt(code: $code) {
    ok
  }
}
    `;
export const DepositUzsDocument = gql`
    mutation depositUzs($code: String!) {
  depositUzs(code: $code) {
    ok
  }
}
    `;
export const DepositAedDocument = gql`
    mutation depositAed($code: String!) {
  depositAed(code: $code) {
    ok
  }
}
    `;
export const WithdrawKztDocument = gql`
    mutation withdrawKzt($amount: Decimal!, $otpCode: String) {
  withdrawKzt(amount: $amount, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawAedDocument = gql`
    mutation withdrawAed($amount: Decimal!, $otpCode: String) {
  withdrawAed(amount: $amount, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawUzsDocument = gql`
    mutation withdrawUzs($amount: Decimal!, $otpCode: String) {
  withdrawUzs(amount: $amount, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawRubDocument = gql`
    mutation withdrawRub($amount: Decimal!, $otpCode: String) {
  withdrawRub(amount: $amount, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawPartnerDocument = gql`
    mutation withdrawPartner($amount: Decimal!, $otpCode: String) {
  withdrawPartner(amount: $amount, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawBtcDocument = gql`
    mutation withdrawBtc($amount: Decimal!, $address: String!, $otpCode: String) {
  withdrawBtc(amount: $amount, address: $address, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawXmrDocument = gql`
    mutation withdrawXmr($amount: Decimal!, $address: String!, $otpCode: String) {
  withdrawXmr(amount: $amount, address: $address, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawLtcDocument = gql`
    mutation withdrawLtc($amount: Decimal!, $address: String!, $otpCode: String) {
  withdrawLtc(amount: $amount, address: $address, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawUsdtDocument = gql`
    mutation withdrawUsdt($amount: Decimal!, $address: String!, $otpCode: String) {
  withdrawUsdt(amount: $amount, address: $address, otpCode: $otpCode) {
    ok
  }
}
    `;
export const WithdrawFnkDocument = gql`
    mutation withdrawFnk($amount: Decimal!, $address: String!, $otpCode: String) {
  withdrawFnk(amount: $amount, address: $address, otpCode: $otpCode) {
    ok
  }
}
    `;
export const OtcCreateAdDocument = gql`
    mutation otcCreateAd($action: String!, $currency: String!, $minSum: Decimal!, $maxSum: Decimal!, $paymentMethod: String!, $description: String!, $priceExtraPercent: Decimal!) {
  otcCreateAd(
    action: $action
    currency: $currency
    minSum: $minSum
    maxSum: $maxSum
    paymentMethod: $paymentMethod
    description: $description
    priceExtraPercent: $priceExtraPercent
  ) {
    ok
  }
}
    `;
export const OtcEditAdDocument = gql`
    mutation otcEditAd($id: String!, $currency: String!, $action: String!, $minSum: Decimal!, $maxSum: Decimal!, $paymentMethod: String!, $description: String!, $priceExtraPercent: Decimal!) {
  otcEditAd(
    id: $id
    action: $action
    currency: $currency
    minSum: $minSum
    maxSum: $maxSum
    paymentMethod: $paymentMethod
    description: $description
    priceExtraPercent: $priceExtraPercent
  ) {
    ok
  }
}
    `;
export const OtcDeleteAdDocument = gql`
    mutation otcDeleteAd($pk: String!) {
  otcDeleteAd(pk: $pk) {
    ok
  }
}
    `;
export const OtcStartDealDocument = gql`
    mutation otcStartDeal($adId: String!) {
  otcStartDeal(adId: $adId) {
    ok
    dealId
  }
}
    `;
export const OtcDealOfferDocument = gql`
    mutation otcDealOffer($pk: String!, $amount: Decimal!) {
  otcDealOffer(pk: $pk, amount: $amount) {
    ok
  }
}
    `;
export const OtcDealAcceptDocument = gql`
    mutation otcDealAccept($pk: String!) {
  otcDealAccept(pk: $pk) {
    ok
  }
}
    `;
export const OtcDealDeclineDocument = gql`
    mutation otcDealDecline($pk: String!) {
  otcDealDecline(pk: $pk) {
    ok
  }
}
    `;
export const OtcDealPaidDocument = gql`
    mutation otcDealPaid($pk: String!) {
  otcDealPaid(pk: $pk) {
    ok
  }
}
    `;
export const OtcDealCompleteDocument = gql`
    mutation otcDealComplete($pk: String!) {
  otcDealComplete(pk: $pk) {
    ok
  }
}
    `;
export const CreateOrderDocument = gql`
    mutation createOrder($symbol: String!, $direction: String!, $type: String!, $amount: Decimal!, $price: Decimal!, $turnover: Decimal!) {
  createOrder(
    symbol: $symbol
    amount: $amount
    type: $type
    direction: $direction
    price: $price
    turnover: $turnover
  ) {
    ok
  }
}
    `;
export const OnCancelOrderDocument = gql`
    mutation onCancelOrder($pk: String!) {
  cancelOrder(pk: $pk) {
    ok
  }
}
    `;
export const GetProfileDocument = gql`
    query getProfile {
  profile {
    id
    name
    email
    phone
    otpEnabled
    otpSecret
    referrer
    emailValidated
    referralCode
    tgUsername
    inviterTgUsername
    rubBalance
    kztBalance
    uzsBalance
    aedBalance
    btcBalance
    xmrBalance
    ltcBalance
    usdtBalance
    fnkBalance
    partnerBalance
    lockedBtc
    lockedUsdt
    lockedFnk
    btcAddress
    ethAddress
    tronAddress
    xmrAddress
    ltcAddress
    referrals {
      ...referral
    }
  }
}
    ${Referral}`;
export const Has2FaDocument = gql`
    query has2FA($email: String!) {
  has2fa(email: $email)
}
    `;
export const GetIndexInfoDocument = gql`
    query getIndexInfo {
  indexPage {
    topText
    topImage
    bottomText1
    bottomImage1
    bottomText2
    bottomImage2
    bottomText3
    bottomImage3
  }
}
    `;
export const GetOperationsDocument = gql`
    query getOperations($currency: String!) {
  getOperations(currency: $currency) {
    deposits {
      ...operation
    }
    withdraws {
      ...operation
    }
  }
}
    ${Operation}`;
export const GetMyOtcAdsDocument = gql`
    query getMyOtcAds {
  myAds {
    ...otcAd
  }
}
    ${OtcAd}`;
export const GetOtcAdsDocument = gql`
    query getOtcAds {
  ads {
    buy {
      ...otcAd
    }
    sell {
      ...otcAd
    }
  }
}
    ${OtcAd}`;
export const GetMyOtcDealsDocument = gql`
    query getMyOtcDeals {
  myDeals {
    ...otcDeal
  }
}
    ${OtcDeal}`;
export const GetDealDocument = gql`
    query getDeal($pk: String!) {
  deal(pk: $pk) {
    ...otcDeal
  }
}
    ${OtcDeal}`;
export const GetBaseSymbolDocument = gql`
    query getBaseSymbol {
  basesymbol
}
    `;
export const GetUserBalanceDocument = gql`
    query getUserBalance($symbol: String!) {
  getBalance(symbol: $symbol)
}
    `;
export const GetCoinsDocument = gql`
    query getCoins($baseSymbol: String!) {
  coins(baseSymbol: $baseSymbol)
}
    `;
export const GetBooksDocument = gql`
    query getBooks($symbol: String!, $limit: Int!) {
  books(symbol: $symbol, limit: $limit) {
    buy {
      type
      symbol
      price
      amounts
      turnovers
    }
    sell {
      type
      symbol
      price
      amounts
      turnovers
    }
  }
}
    `;
export const GetOrderCurrentDocument = gql`
    query getOrderCurrent($symbol: String!, $limit: Int!, $pageNo: Int!) {
  orderCurrent(symbol: $symbol, limit: $limit, pageNo: $pageNo) {
    count
    limit
    pageNo
    items {
      orderId
      price
      type
      direction
      status
      amount
      turnover
      symbol
      time
      coinSymbol
      baseSymbol
    }
  }
}
    `;
export const GetCoinDocument = gql`
    query getCoin($symbol: String!) {
  coin(symbol: $symbol) {
    baseCoinScale
    baseSymbol
    coinScale
    coinSymbol
    rubRate
    usdRate
    enable
    fee
    symbol
  }
}
    `;
export const GetOrderHistoryDocument = gql`
    query getOrderHistory($symbol: String!, $limit: Int!, $pageNo: Int!) {
  orderHistory(symbol: $symbol, limit: $limit, pageNo: $pageNo) {
    count
    limit
    pageNo
    items {
      orderId
      price
      type
      direction
      status
      amount
      turnover
      tradedAmount
      tradedTurnover
      symbol
      time
      coinSymbol
      baseSymbol
      timeComplited
      timeCanceled
    }
  }
}
    `;
export const GetBalancesAllDocument = gql`
    query getBalancesAll {
  getBalancesAll {
    balance
    freezeBalance
    symbol
  }
}
    `;
export const GetBalancesOneDocument = gql`
    query getBalancesOne($symbol: String!) {
  getBalancesOne(symbol: $symbol) {
    balance
    freezeBalance
    symbol
  }
}
    `;
export const GetMyExmoCodesDocument = gql`
    query getMyExmoCodes {
  myExmoCodes {
    code
    amount
    currency
    status
    createdAt
    activatedAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    tokenAuth(variables: TokenAuthVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TokenAuth> {
      return withWrapper((wrappedRequestHeaders) => client.request<TokenAuth>(TokenAuthDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'tokenAuth');
    },
    register(variables: RegisterVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Register> {
      return withWrapper((wrappedRequestHeaders) => client.request<Register>(RegisterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'register');
    },
    passwordReset(variables: PasswordResetVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PasswordReset> {
      return withWrapper((wrappedRequestHeaders) => client.request<PasswordReset>(PasswordResetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'passwordReset');
    },
    updateName(variables: UpdateNameVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateName> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateName>(UpdateNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateName');
    },
    updatePassword(variables: UpdatePasswordVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePassword> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePassword>(UpdatePasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePassword');
    },
    updatePhone(variables: UpdatePhoneVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePhone> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePhone>(UpdatePhoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePhone');
    },
    toggle2fa(variables: Toggle2faVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Toggle2fa> {
      return withWrapper((wrappedRequestHeaders) => client.request<Toggle2fa>(Toggle2faDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggle2fa');
    },
    updateTg(variables: UpdateTgVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTg> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTg>(UpdateTgDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateTg');
    },
    depositRub(variables: DepositRubVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DepositRub> {
      return withWrapper((wrappedRequestHeaders) => client.request<DepositRub>(DepositRubDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'depositRub');
    },
    redeemExmoCode(variables: RedeemExmoCodeVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RedeemExmoCode> {
      return withWrapper((wrappedRequestHeaders) => client.request<RedeemExmoCode>(RedeemExmoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'redeemExmoCode');
    },
    checkExmoCode(variables: CheckExmoCodeVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CheckExmoCode> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckExmoCode>(CheckExmoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkExmoCode');
    },
    createExmoCode(variables: CreateExmoCodeVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateExmoCode> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateExmoCode>(CreateExmoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createExmoCode');
    },
    depositKzt(variables: DepositKztVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DepositKzt> {
      return withWrapper((wrappedRequestHeaders) => client.request<DepositKzt>(DepositKztDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'depositKzt');
    },
    depositUzs(variables: DepositUzsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DepositUzs> {
      return withWrapper((wrappedRequestHeaders) => client.request<DepositUzs>(DepositUzsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'depositUzs');
    },
    depositAed(variables: DepositAedVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DepositAed> {
      return withWrapper((wrappedRequestHeaders) => client.request<DepositAed>(DepositAedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'depositAed');
    },
    withdrawKzt(variables: WithdrawKztVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawKzt> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawKzt>(WithdrawKztDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawKzt');
    },
    withdrawAed(variables: WithdrawAedVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawAed> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawAed>(WithdrawAedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawAed');
    },
    withdrawUzs(variables: WithdrawUzsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawUzs> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawUzs>(WithdrawUzsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawUzs');
    },
    withdrawRub(variables: WithdrawRubVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawRub> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawRub>(WithdrawRubDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawRub');
    },
    withdrawPartner(variables: WithdrawPartnerVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawPartner> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawPartner>(WithdrawPartnerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawPartner');
    },
    withdrawBtc(variables: WithdrawBtcVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawBtc> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawBtc>(WithdrawBtcDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawBtc');
    },
    withdrawXmr(variables: WithdrawXmrVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawXmr> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawXmr>(WithdrawXmrDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawXmr');
    },
    withdrawLtc(variables: WithdrawLtcVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawLtc> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawLtc>(WithdrawLtcDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawLtc');
    },
    withdrawUsdt(variables: WithdrawUsdtVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawUsdt> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawUsdt>(WithdrawUsdtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawUsdt');
    },
    withdrawFnk(variables: WithdrawFnkVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WithdrawFnk> {
      return withWrapper((wrappedRequestHeaders) => client.request<WithdrawFnk>(WithdrawFnkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'withdrawFnk');
    },
    otcCreateAd(variables: OtcCreateAdVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcCreateAd> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcCreateAd>(OtcCreateAdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcCreateAd');
    },
    otcEditAd(variables: OtcEditAdVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcEditAd> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcEditAd>(OtcEditAdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcEditAd');
    },
    otcDeleteAd(variables: OtcDeleteAdVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcDeleteAd> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcDeleteAd>(OtcDeleteAdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcDeleteAd');
    },
    otcStartDeal(variables: OtcStartDealVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcStartDeal> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcStartDeal>(OtcStartDealDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcStartDeal');
    },
    otcDealOffer(variables: OtcDealOfferVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcDealOffer> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcDealOffer>(OtcDealOfferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcDealOffer');
    },
    otcDealAccept(variables: OtcDealAcceptVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcDealAccept> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcDealAccept>(OtcDealAcceptDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcDealAccept');
    },
    otcDealDecline(variables: OtcDealDeclineVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcDealDecline> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcDealDecline>(OtcDealDeclineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcDealDecline');
    },
    otcDealPaid(variables: OtcDealPaidVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcDealPaid> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcDealPaid>(OtcDealPaidDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcDealPaid');
    },
    otcDealComplete(variables: OtcDealCompleteVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OtcDealComplete> {
      return withWrapper((wrappedRequestHeaders) => client.request<OtcDealComplete>(OtcDealCompleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'otcDealComplete');
    },
    createOrder(variables: CreateOrderVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOrder> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrder>(CreateOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createOrder');
    },
    onCancelOrder(variables: OnCancelOrderVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OnCancelOrder> {
      return withWrapper((wrappedRequestHeaders) => client.request<OnCancelOrder>(OnCancelOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'onCancelOrder');
    },
    getProfile(variables?: GetProfileVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetProfile> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProfile>(GetProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProfile');
    },
    has2FA(variables: Has2FaVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Has2Fa> {
      return withWrapper((wrappedRequestHeaders) => client.request<Has2Fa>(Has2FaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'has2FA');
    },
    getIndexInfo(variables?: GetIndexInfoVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetIndexInfo> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetIndexInfo>(GetIndexInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getIndexInfo');
    },
    getOperations(variables: GetOperationsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOperations> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOperations>(GetOperationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOperations');
    },
    getMyOtcAds(variables?: GetMyOtcAdsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMyOtcAds> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMyOtcAds>(GetMyOtcAdsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMyOtcAds');
    },
    getOtcAds(variables?: GetOtcAdsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOtcAds> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOtcAds>(GetOtcAdsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOtcAds');
    },
    getMyOtcDeals(variables?: GetMyOtcDealsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMyOtcDeals> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMyOtcDeals>(GetMyOtcDealsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMyOtcDeals');
    },
    getDeal(variables: GetDealVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDeal> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDeal>(GetDealDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDeal');
    },
    getBaseSymbol(variables?: GetBaseSymbolVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBaseSymbol> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBaseSymbol>(GetBaseSymbolDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBaseSymbol');
    },
    getUserBalance(variables: GetUserBalanceVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserBalance> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserBalance>(GetUserBalanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserBalance');
    },
    getCoins(variables: GetCoinsVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCoins> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCoins>(GetCoinsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCoins');
    },
    getBooks(variables: GetBooksVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBooks> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBooks>(GetBooksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBooks');
    },
    getOrderCurrent(variables: GetOrderCurrentVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOrderCurrent> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOrderCurrent>(GetOrderCurrentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOrderCurrent');
    },
    getCoin(variables: GetCoinVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCoin> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCoin>(GetCoinDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCoin');
    },
    getOrderHistory(variables: GetOrderHistoryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOrderHistory> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOrderHistory>(GetOrderHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOrderHistory');
    },
    getBalancesAll(variables?: GetBalancesAllVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBalancesAll> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBalancesAll>(GetBalancesAllDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBalancesAll');
    },
    getBalancesOne(variables: GetBalancesOneVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBalancesOne> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBalancesOne>(GetBalancesOneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBalancesOne');
    },
    getMyExmoCodes(variables?: GetMyExmoCodesVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMyExmoCodes> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMyExmoCodes>(GetMyExmoCodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMyExmoCodes');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;