import { History } from "history";

export { RouterStore } from "mobx-react-router";
export { RootStore } from "./RootStore";
export { ModalStore } from './ModalStore';
export { AuthStore } from './AuthStore';
export { InfoStore } from './InfoStore';
export { OrdersStore } from './OrdersStore';
export { CoinStore } from './CoinStore';
export { CoinBaze } from './CoinBaze';

// @ts-ignore
export class HistoryStore implements History {}
