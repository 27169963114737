import React from 'react';
import classNames from "classnames";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../stores";
import Moment from "react-moment";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

interface IProgramPageProps {
}

interface IProgramPageState {
    activeTab: 'stats' | 'referrals';
}

@observer
export class ProgramPage extends React.Component<IProgramPageProps, IProgramPageState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IProgramPageState = {
        activeTab: 'stats',
    }

    render() {
        const { activeTab } = this.state;

        const textLink = `– единственная биржа, с пополнением и выводом наличных рублей без комиссии. 
        Работаем во всех крупных городах России. Для обмена доступны BTC, USDT, RUB. 
        Выдаем бесплатные лимиты участникам. Для получения бонуса 1 000 рублей настоящих денег на счет - регистрация по
        ссылке: ${this.authStore.referralLink}`

        return (
            <main className="main">
                <section className="otc-section">
                    <div className="container">
                        <div className="program-content">
                            <div className="main-content">
                                <div className="main-block">
                                    <h2 className="operation-title">Информация о программе</h2>
                                    <div className="program-wrap">
                                        <div className="program">
                                            <div className="program__wrap">
                                                <span className="program__number">1</span>
                                                <div className="program__info">
                                                    <p className="base-text">Получай 0.1% от каждой сделки своих
                                                        рефералов! Пригласи друзей и коллег, пока это не сделал кто-то
                                                        другой!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="program">
                                            <div className="program__wrap">
                                                <span className="program__number">2</span>
                                                <div className="program__info">
                                                    <p className="base-text">Расскажи как работать с биржей.io, удобно
                                                        покупая и продавая биткоин и USDT по самому лучшему курсу.
                                                        <div className="program__link">
                                                            <span className="program__link-title">Ваша ссылка</span>
                                                            <div className="program__link-wrap">
                                                                <p className="program__link-text">{this.authStore.referralLink}</p>
                                                                <button className="program__link-copy" type="button" onClick={() => copy(this.authStore.referralLink) && toast.success('Ссылка скопирована')}>
                                                                    <svg>
                                                                        <use href="#copy"/>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="program__link">
                                                            <span className="program__link-title">Ваша ссылка с текстом</span>
                                                            <div className="program__link-wrap">
                                                                <p className="program__link-text">{textLink}</p>
                                                                <button className="program__link-copy" type="button" onClick={() => copy(textLink) && toast.success('Текст скопирован')}>
                                                                    <svg>
                                                                        <use href="#copy"/>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        </p>
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="main-block">
                                    <div className="deal js-deal-tabs">
                                        <ul className="deal__list js-deal-list">
                                            <li
                                                className={classNames('deal__item', { active: activeTab === 'stats' })}
                                                onClick={() => this.setState({ activeTab: 'stats' })}
                                            >
                                                Статистика
                                            </li>
                                            <li
                                                className={classNames('deal__item', { active: activeTab === 'referrals' })}
                                                onClick={() => this.setState({ activeTab: 'referrals' })}
                                            >
                                                Рефералы
                                            </li>
                                        </ul>
                                        <div className={classNames('deal__content', { active: activeTab === 'stats' })}>
                                            <div className="table statistic scroll-wrap">
                                                <div className="table__wrap">
                                                    <h3 className="table__title">Статистика</h3>
                                                    <div className="table__head">
                                                        <div className="table__field table__field_row">
                                                            <span>Время</span>
                                                            {/*<div className="table__nav">
                                                                <button className="table__button table__button_top" type="button"/>
                                                                <button className="table__button table__button_down" type="button"/>
                                                            </div>*/}
                                                        </div>
                                                        <div className="table__field"><span>Сумма</span></div>
                                                        <div className="table__field"><span>CFR</span></div>
                                                        <div className="table__field"><span>Бонус (RUB)</span></div>
                                                    </div>
                                                    <div className="table__body scroll-wrap">
                                                        {/*<div className="table__row">
                                                            <div className="table__field"><span>23.08.2020 10:58</span>
                                                            </div>
                                                            <div className="table__field"><span>1 047 996</span></div>
                                                            <div className="table__field"><span>889 936</span></div>
                                                            <div className="table__field"><span>889 936</span></div>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classNames('deal__content', { active: activeTab === 'referrals' })}>
                                            <div className="table referal scroll-wrap">
                                                <div className="table__wrap">
                                                    <h3 className="table__title">Рефералы</h3>
                                                    <div className="table__head">
                                                        <div className="table__field table__field_row">
                                                            <span>ID</span>
                                                            {/*<div className="table__nav">*/}
                                                            {/*    <button className="table__button table__button_top" type="button"/>*/}
                                                            {/*    <button className="table__button table__button_down" type="button"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                        <div className="table__field"><span>Общий бонус (₽)</span></div>
                                                        <div className="table__field"><span>Дата регистрации</span></div>
                                                    </div>
                                                    <div className="table__body scroll-wrap">
                                                        {this.authStore.profile?.referrals.map(r => (
                                                            <div className="table__row">
                                                                <div className="table__field"><span>{r.name}</span></div>
                                                                <div className="table__field"><span>{r.referralBonuses}</span></div>
                                                                <div className="table__field"><span><Moment date={r.dateJoined} format='L LT' /></span></div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}